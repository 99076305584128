import { ServiceAreaType } from '@app/contexts/service-area-context/types';

export type Prop = {
  selectedType: ServiceAreaType;
  onSelectType: (t: ServiceAreaType) => void;
  overrideClass?: string;
}

const ServiceAreaTypeToggler = ({ selectedType, onSelectType, overrideClass = '' } : Prop) => {
  const types = [
    {
      label: 'ZIP Code',
      type: 'zip',
      onClick: () => onSelectType('zip'),
    },
    {
      label: 'Address',
      type: 'address',
      onClick: () => onSelectType('address'),
    },
  ];

  return (
    <div
      className={`${overrideClass}  md:w-1/2
      label-sm flex flex-row items-center bg-background-mid-em dark:bg-background-mid-em-dark rounded-lg p-1`}
    >
      {types.map((type) => (
        <div
          key={type.type}
          onClick={type.onClick}
          role="button"
          tabIndex={0}
          onKeyPress={type.onClick}
          className={`
            ${selectedType === type.type ? 'bg-primary-light text-light' : 'text-color-high-em'}
            h-[28px] w-1/2 flex items-center justify-center rounded-lg
          `}
        >
          <span>
            {type.label}
          </span>
        </div>
      ))}
    </div>
  );
};

export default ServiceAreaTypeToggler;
