import {
  signOut, SignOutParams, useSession,
} from 'next-auth/react';
import {
  Fragment, ReactNode, useEffect,
} from 'react';
import Loader from '@app/components/loader';
import { useAppContext } from '@app/contexts/app-context';
import { useUserContext } from '@app/contexts/user-context';
import { useRouter } from 'next/router';
import { LOGIN_PAGE } from '@app/constants/routes';
import { isEmpty } from 'lodash';
import { APPROVED_USER } from '@app/contexts/user-context/constants';
import StepperComponent from './stepper-component';
import OnboardingModal from './onboarding-modal';

export interface AuthGuardProps {
  children: ReactNode,
}

export const handleSignOut = async (signOutOptions?: SignOutParams) => {
  await signOut(signOutOptions);
};

const AuthGuard = ({ children }: AuthGuardProps) => {
  const { status } = useSession();
  const { isLoading } = useAppContext();
  const { currentUser, onboardingSteps } = useUserContext();
  const router = useRouter();

  useEffect(() => {
    if (status === 'loading') return; // Do nothing while loading
    if (status === 'unauthenticated') {
      // If not authenticated, force log in
      router.push(LOGIN_PAGE);
    }
  }, [status]);

  if (status === 'authenticated' && !isLoading('getCurrentUser') && !isEmpty(currentUser)) {
    if (currentUser.statuses.userStatus === 'verified'
      && currentUser.statuses.vendorStatus === 'approved'
      && !onboardingSteps.includes(APPROVED_USER.key)
    ) {
      return <StepperComponent userAndVendorStatus="approved" />;
    }

    if (currentUser.statuses.userStatus === 'pending' && currentUser.statuses.vendorStatus === 'pending') {
      return <StepperComponent userAndVendorStatus="verification" />;
    }
    if (currentUser.statuses.userStatus === 'verified' && currentUser.statuses.vendorStatus === 'pending') {
      return <StepperComponent userAndVendorStatus="pending" />;
    }
    if (currentUser.statuses.vendorStatus === 'rejected') {
      if (currentUser.statuses.userStatus === 'pending') {
        return <StepperComponent userAndVendorStatus="rejectedWitPendingEmail" />;
      }
      return <StepperComponent userAndVendorStatus="rejected" />;
    }
    if (currentUser.statuses.userStatus === 'pending' && currentUser.statuses.vendorStatus === 'approved') {
      return <StepperComponent userAndVendorStatus="approvedWitPendingEmail" />;
    }

    return (
      <Fragment>
        <OnboardingModal />
        {children}
      </Fragment>
    );
  }
  // Session is being fetched, or no user.
  // If no user, useEffect() will redirect.
  return <Loader />;
};

export default AuthGuard;
