import { HOME_PAGE } from '@app/constants/routes';
import { useAppContext } from '@app/contexts/app-context';
import { useUserContext } from '@app/contexts/user-context';
import { ALL_SETUP } from '@app/contexts/user-context/constants';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Button from '../button';

const AllSetup = () => {
  const router = useRouter();
  const { addOnboardingStep } = useUserContext();
  const { isLoading, closeOnboardingModal } = useAppContext();

  const message = "Greate You're all set up!";
  const onClick = async () => {
    await addOnboardingStep(ALL_SETUP.key);
    router.push(HOME_PAGE);
    closeOnboardingModal();
  };
  return (
    <div className="flex flex-col items-center gap-y-8 p-10 md:p-20">
      <div>
        <Image
          src="/images/onboarding/all-setup.png"
          width={220}
          height={160}
        />
      </div>
      <span className="h6 md:h2">{message}</span>
      <span className="text-center md:px-10">
        To view and update your service offerings again, go to vendor profile
        and edit your desired offerings respectively. Happy Bidding!
      </span>
      <Button
        disabled={isLoading('handleAddOnboardingStep')}
        onClick={onClick}
      >
        {isLoading('handleAddOnboardingStep') ? 'Proceeding to Dashboard' : 'Go to Dashboard'}
      </Button>
    </div>
  );
};

export default AllSetup;
