/* eslint-disable camelcase */
import { ReactNode } from 'react';

export interface WasteStreamPanelContextProps {
  children: ReactNode,
}

export type WasteStream = {
  id: number,
  createdAt: Date,
  createdBy: number,
  updatedAt: Date | null
  updatedBy: number | null,
  name: string,
};

export type VendorWasteStream = {
  id: number,
  wasteStream: WasteStream
  createdAt: Date,
  createdBy: number,
  updatedAt: Date | null
  updatedBy: number | null,
};

export type VendorWasteStreamState = {
  vendorWasteStreams: VendorWasteStream[]
};

export type Context = VendorWasteStreamState & {
  selectedVendorWasteStream: VendorWasteStream,
  setSelectedVendorWasteStream: (v: VendorWasteStream | null) => void;
  handleAddWasteStreams: (wasteStreamIds: number[]) => void;
  handleDeleteWasteStream: (vendorWasteStreamId: number) => void;
};

export const SET_VENDOR_WASTE_STREAMS = 'SET_VENDOR_WASTE_STREAMS';
export const ADD_VENDROR_WASTE_STREAMS = 'ADD_VENDOR_WASTE_STREAMS';
export const DELETE_VENDOR_WASTE_STREAM = 'DELETE_VENDOR_WASTE_STREAM';

export type SetVendorWasteStreams = {
  type: typeof SET_VENDOR_WASTE_STREAMS,
  vendorWasteStreams: VendorWasteStream[]
};

export type AddVendorWasteStreams = {
  type: typeof ADD_VENDROR_WASTE_STREAMS,
  vendorWasteStreams: VendorWasteStream[]
};

export type DeleteVendorWasteStream = {
  type: typeof DELETE_VENDOR_WASTE_STREAM,
  vendorWasteStreamId: number
};

export type VendorWasteStreamActionTypes =
  | SetVendorWasteStreams
  | DeleteVendorWasteStream
  | AddVendorWasteStreams
