// Configurations
export const ELEMENT_ROOT_ID = '#__next';
export const APP_URL = process.env.NEXT_PUBLIC_APP_URL;
export const EXTERNAL_API = process.env.NEXT_PUBLIC_EXTERNAL_API || 'https://simplewaste-api-dev.herokuapp.com';

// HTML document defaults
export const DEFAULT_META_TITLE = 'SimpleWaste | Bidding made easy';
export const DEFAULT_META_DESCRIPTION = `Log onto the SimpleWaste vendor portal and begin accessing bids immediately. 
Receive instant notifications on new bid opportunities in your market.`;

// Common default values
export const DEFAULT_LIMIT = 20;
export const DEFAULT_PAGE = 1;

// react-table pagination defaults
export const DEFAULT_PAGE_INDEX = 0;
export const DEFAULT_PAGE_SIZES = [5, 10, 20] as const;
export const DEFAULT_PAGE_SIZE: typeof DEFAULT_PAGE_SIZES[number] = 10;

// Google Map Integration defaults
export const DEFAULT_GOOGLE_MAP_ZOOM = 7;
export const DEFAULT_GOOGLE_MAP_HEIGHT = '40vh';
export const DEFAULT_GOOGLE_MAP_WIDTH = '100%';

// Conversions
export const METER_TO_MILES = 1609.344;

// Dimensions
export const SIDEBAR_WIDTH = 74;
export const NAV_HEIGHT = 64;

// Z-INDECES
export const Z_INDECES = {
  AUTH_LAYOUT: 0,
  NAVBAR: 40,
  SEARCH_FILTER: 20,
  SIDEBAR: 30,
  HOW_TO_BID: 50,
  NAV_AUTH_MODAL: 50,
  MODAL: 40,
  DROPDOWN: 50,
};
