import { Dispatch } from 'react';
import {
  UserState,
  UserActionTypes,
  SetCurrentUser,
  SET_CURRENT_USER,
  User,
  ADD_ONBOARDING_STEP,
  SET_ONBOARDING_STEPS,
  OnboardingKeyType,
  AddOnboardingStep,
  SetOnboardingSteps,
  REMOVE_ONBOARDING_STEP,
  RemoveOnboardingStep,
} from '@app/contexts/user-context/types';
import { uniq } from 'lodash';

export const initialState: UserState = {
  currentUser: null,
  onboardingSteps: [],
};

export default function reducer(
  state: UserState = initialState,
  action: UserActionTypes,
): UserState {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.user,
      };
    case ADD_ONBOARDING_STEP: {
      return {
        ...state,
        onboardingSteps: uniq([...state.onboardingSteps, action.step]),
      };
    }
    case REMOVE_ONBOARDING_STEP: {
      return {
        ...state,
        onboardingSteps: state.onboardingSteps.filter((s) => s !== action.step),
      };
    }
    case SET_ONBOARDING_STEPS: {
      return {
        ...state,
        onboardingSteps: action.steps,
      };
    }
    default:
      return state;
  }
}

export const setCurrentUser = (dispatch: Dispatch<SetCurrentUser>) => (user: User | null) => dispatch({
  type: SET_CURRENT_USER,
  user,
});

export const setOnboardingSteps = (dispatch: Dispatch<SetOnboardingSteps>) => (steps: OnboardingKeyType[]) => dispatch({
  type: SET_ONBOARDING_STEPS,
  steps,
});

export const addOnboardingStep = (dispatch: Dispatch<AddOnboardingStep>) => (step: OnboardingKeyType) => dispatch({
  type: ADD_ONBOARDING_STEP,
  step,
});

export const removeOnboardingStep = (
  dispatch: Dispatch<RemoveOnboardingStep>,
) => (step: OnboardingKeyType) => dispatch({
  type: REMOVE_ONBOARDING_STEP,
  step,
});
