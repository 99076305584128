import {
  useState, Fragment, useEffect, useMemo, useRef,
} from 'react';
import { useVendorPanelModalContext } from '@app/contexts/vendor-panel-modal-context';
import Modal from '@app/components/modal';
import Button from '@app/components/button';
import { useContainerPanelContext } from '@app/contexts/container-panel-context/index';
import { Form } from 'react-final-form';
import FormFieldReactSelect from '@app/forms/fields/react-select';
import { isEmpty } from 'lodash';
import { useUserContext } from '@app/contexts/user-context';
import { useThemeContext } from '@app/contexts/theme-context';
import { ContainerSize, VendorContainer } from '@app/contexts/container-panel-context/types';
import EditVendorContainerForm from '@app/forms/edit-vendor-container';
import Icon from '@mdi/react';
import {
  mdiChevronRight,
  mdiCloseThick, mdiDumpTruck, mdiMapCheck, mdiPlusThick, mdiSquareEditOutline, mdiTrashCan,
} from '@mdi/js';
import DisabledContainerInfo from './disabled-container-info';
import { optionType } from './react-select';

export type VendorContainerListProp = {
  isOnboarding?: boolean
}

const VendorContainerList = ({ isOnboarding = false } : VendorContainerListProp) => {
  const { currentUser } = useUserContext();
  const {
    isContainerTableAddMode,
    setIsContainerTableAddMode,
    isOpenContainerTable,
    openContainerTable,
    closeContainerTable,
    openEditContainerModal,
  } = useVendorPanelModalContext();
  const {
    containers,
    handleDeleteContainer,
    setContainerFormData,
    handleAddContainer,
    fetchNoSizeContainerSize,
  } = useContainerPanelContext();
  const formRef = useRef(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [noSizeContainerSize, setNoSizeContainerSize] = useState<ContainerSize|null>(null);
  const [deleteContainer, setDeleteContainer] = useState<VendorContainer | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isAddingNew, setIsaddingNew] = useState(isOnboarding);
  const { isMobile, isTablet } = useThemeContext();

  useEffect(() => {
    async function init() {
      const result = await fetchNoSizeContainerSize();
      setNoSizeContainerSize(result);
    }
    init();
  }, []);

  useEffect(() => {
    if (!isContainerTableAddMode) return;
    setIsaddingNew(true);
  }, [isContainerTableAddMode]);

  useEffect(() => {
    if (isConfirmationModalOpen === false) {
      setDeleteContainer(null);
    }
  }, [isConfirmationModalOpen]);

  const handleEditAction = (item: VendorContainer) => {
    openEditContainerModal();
    setContainerFormData(item);
  };

  const closeAndResetModal = () => {
    closeContainerTable();
    setIsaddingNew(false);
    setIsContainerTableAddMode(false);
  };

  const handleDeleteAction = (item: VendorContainer) => {
    setIsConfirmationModalOpen(true);
    setDeleteContainer(item);
  };

  const confirmDelete = async () => {
    try {
      setIsDeleting(true);
      await handleDeleteContainer(deleteContainer);
    } catch (error) {
      setIsDeleting(false);
      console.error(error);
    } finally {
      setIsDeleting(false);
      setIsConfirmationModalOpen(false);
    }
  };

  const handleOnSubmit = async (payload) => {
    if (currentUser?.vendor?.id == null) return;
    const body = {
      vendorId: currentUser.vendor.id,
      containerTypeId: payload.containerType.value,
      containerSizeIds: payload.containerSizes.map((cs) => cs.value),
    };

    await handleAddContainer(body);
    setIsaddingNew(isOnboarding);
    if (!isOnboarding || formRef.current == null) return;
    formRef.current.reset();
  };

  const isNoSizeContainerType = (containerType: optionType) => {
    const isNoSize = noSizeContainerSize != null
    && noSizeContainerSize.containerTypes.some((t) => t.id === +containerType.value);
    return isNoSize;
  };

  const renderForm = useMemo(() => (
    <div>
      <Form
        onSubmit={handleOnSubmit}
        render={({
          handleSubmit, submitting, values, form,
        }) => (
          <form onSubmit={handleSubmit}>
            <div
              className={`w-full flex  md:flex-row  
              rounded-xl md:space-x-2  text-xs
              flex-col space-y-1 md:space-y-0
              py-3`}
            >
              <div className="w-full">
                <FormFieldReactSelect
                  apiUrl="/bidding/vendor-container/options/container-type"
                  name="containerType"
                  label=""
                  required
                  placeholder="Type"
                  initialValue={null}
                  onSelect={(containerType) => {
                    if (containerType != null && isNoSizeContainerType(containerType)) {
                      form.change('containerSizes', [{
                        value: noSizeContainerSize.id, label: noSizeContainerSize.name,
                      }]);
                    } else {
                      form.change('containerSizes', []);
                    }
                  }}
                />
              </div>
              <div className="w-full">
                <FormFieldReactSelect
                  selectKey={values.containerType?.value}
                  disabled={values.containerType?.value == null
                    || (values.containerType != null && isNoSizeContainerType(values.containerType))}
                  apiUrl={`/options/container-size/${values.containerType?.value}`}
                  name="containerSizes"
                  isMulti
                  label=""
                  required
                  initialValue={[]}
                  placeholder={values.containerType?.value == null ? <DisabledContainerInfo /> : 'Size'}
                  closeMenuOnSelect={false}
                  showCheckBox
                  hideSelectedOptions={false}
                />
              </div>
              <div className="flex items-center space-x-2">
                <Button
                  size="xs"
                  type="submit"
                  disabled={isEmpty(values)
                    || isEmpty(values.containerSizes)
                    || isEmpty(values.containerType)
                    || submitting}
                  full={isMobile}
                  onClick={() => {
                    formRef.current = form;
                  }}
                >
                  {submitting ? 'Saving..' : 'Add'}
                </Button>
                {!isOnboarding && (
                <Button buttonType="secondary" full={isMobile} size="xs" onClick={() => setIsaddingNew(false)}>
                  <Icon path={mdiCloseThick} size={0.6} />
                </Button>
                )}
              </div>
            </div>
          </form>
        )}
      />
    </div>
  ), [isOnboarding, isMobile, currentUser?.vendor?.id, noSizeContainerSize]);

  const renderList = useMemo(
    () => (
      <div>
        <div className="p-3">
          <div
            className={`flex items-center flex-row justify-between  ${
              isOnboarding ? '' : 'border-b pb-4'
            }`}
          >
            {isOnboarding ? (
              <div className="text-left">
                <div className="pb-3">
                  <Icon
                    path={mdiMapCheck}
                    size={1.5}
                    className="text-secondary"
                  />
                </div>
                <span className="label-lg md:h3 text-primary-dark dark:text-primary-light">
                  Add Container
                </span>
                <br />
                <span>
                  Find the right offerings with the right containers needed. Add
                  containers that you have.
                </span>
              </div>
            ) : (
              <div className="flex flex-row items-center space-x-2 pl-2">
                <Icon path={mdiDumpTruck} size={1} className="text-secondary" />
                <h4>Containers</h4>
              </div>
            )}
            {!isAddingNew && currentUser.allowed.canEditVendorContainer && (
              <div>
                <div className="xs:hidden">
                  <Button
                    buttonType="secondary"
                    onClick={() => setIsaddingNew(true)}
                    className="dark:!bg-background-mid-em-dark"
                    size="xs"
                  >
                    <Icon path={mdiPlusThick} size={0.6} />
                  </Button>
                </div>
                <div className="hidden xs:block">
                  <Button
                    startIcon={<Icon path={mdiPlusThick} size={0.6} />}
                    buttonType="secondary"
                    onClick={() => setIsaddingNew(true)}
                    className="dark:!bg-background-mid-em-dark"
                    size="xs"
                  >
                    Add New
                  </Button>
                </div>
              </div>
            )}
          </div>
          {isAddingNew
            && currentUser.allowed.canEditVendorContainer
            && renderForm}
          <div
            className="label-sm-bold mt-4 bg-background-low-em border-b-2 text-text-color-mid-em
          dark:bg-background-stroke-dark
          dark:border-background-stroke-dark
          dark:text-text-color-low-em"
          >
            <div className="grid grid-cols-12 p-2">
              <div className="col-span-4 md:col-span-5 ml-2">Type</div>
              <div className="col-span-4 md:col-span-5">Size</div>
              {!isOnboarding
                && currentUser.allowed.canEditVendorContainer && (
                  <div className="col-span-2 md:col-span-1">Edit</div>
              )}
              {currentUser.allowed.canDeleteVendorContainer && (
                <div className="col-span-2 md:col-span-1">Delete</div>
              )}
            </div>
          </div>
          <div
            style={{ maxHeight: isOnboarding ? '25vh' : 350 }}
            className="overflow-auto mb-3"
          >
            {isEmpty(containers) && <small>No Containers found...</small>}
            {containers.sort().map((item) => (
              <div
                key={item.id}
                className="text-body-sm text-text-color-mid-em"
              >
                <div className="grid grid-cols-12 p-2 ">
                  <div className="col-span-4 md:col-span-5 ml-2 mr-1 truncate">
                    {item.container.containerType.name}
                  </div>
                  <div className="col-span-4 md:col-span-5 mr-1 truncate">
                    {item.container.containerSizes
                      ?.map((cs) => cs.name)
                      .join(', ')}
                  </div>
                  {!isOnboarding
                    && currentUser.allowed.canEditVendorContainer && (
                      <div className="col-span-2 md:col-span-1">
                        <button
                          type="button"
                          onClick={() => handleEditAction(item)}
                        >
                          <Icon path={mdiSquareEditOutline} size={0.6} className="text-primary" />
                        </button>
                      </div>
                  )}
                  {currentUser.allowed.canDeleteVendorContainer && (
                    <div className="col-span-2 md:col-span-1">
                      <button
                        type="button"
                        onClick={() => handleDeleteAction(item)}
                      >
                        <Icon path={mdiTrashCan} size={0.6} className="text-primary" />
                      </button>
                    </div>
                  )}
                </div>
                <div className="w-full bg-background-stroke h-px dark:bg-background-stroke-dark" />
              </div>
            ))}
          </div>
        </div>
        {!isOnboarding && (
          <div className="text-right p-3">
            <Button
              buttonType="secondary"
              onClick={closeAndResetModal}
              className="dark:!bg-transparent"
              size="xs"
            >
              Close
            </Button>
          </div>
        )}
      </div>
    ),
    [
      isOnboarding,
      currentUser.allowed.canDeleteVendorContainer,
      currentUser.allowed.canEditVendorContainer,
      containers,
      isAddingNew,
      renderForm,
    ],
  );

  return (
    <Fragment>
      {isOnboarding ? renderList : (
        <Modal
          isOpen={isOpenContainerTable}
          onRequestClose={closeAndResetModal}
          overrideStyles={{
            content: {
              width: isMobile ? '90vw' : '70vw',
              maxHeight: '70vh',
              padding: isMobile ? 0 : 'auto',
            },
          }}
        >
          {renderList}
        </Modal>
      )}
      <Modal isOpen={isConfirmationModalOpen} onRequestClose={() => setIsConfirmationModalOpen(false)}>
        <div>
          <h1 className="text-lg font-medium">
            Confirm deletion of Container ID
            {' '}
            {deleteContainer !== null ? deleteContainer.id : 'N/A'}
          </h1>
          <div className="grid grid-cols-1 gap-2 mb-8 mt-6">
            <p>
              Type:
              {' '}
              {deleteContainer !== null ? deleteContainer.container.containerType.name : 'N/A'}
            </p>
            <p>
              Size:
              {' '}
              {deleteContainer !== null
                ? deleteContainer.container.containerSizes.map((cs) => cs.name).join(', ')
                : 'N/A'}
            </p>
          </div>
        </div>
        <div className="flex space-x-3">
          <Button full disabled={isDeleting} onClick={confirmDelete}>{isDeleting ? 'Deleting...' : 'Confirm'}</Button>
          <Button full buttonType="secondary" onClick={() => setIsConfirmationModalOpen(false)}>Cancel</Button>
        </div>
      </Modal>
      <EditVendorContainerForm noSizeContainerSize={noSizeContainerSize} />
      {!isOnboarding && (
      <button
        type="button"
        className="bg-light drop-shadow-md p-2 rounded-lg flex justify-center
        dark:bg-background-main-dark dark:border-2 dark:border-background-stroke-dark"
        onClick={openContainerTable}
      >
        <span className="text-primary mx-1 flex flex-row items-center">
          {!isTablet && (
            <span>
              View All
              {' '}
            </span>
          )}
          <Icon className={isTablet ? 'ml-1' : 'ml-2'} size={0.7} path={mdiChevronRight} />
        </span>
      </button>
      )}
    </Fragment>
  );
};

export default VendorContainerList;
