import { FunctionComponent } from 'react';
import VendorPanelModalProvider from '@app/contexts/vendor-panel-modal-context';
import { ContainerPanelProvider } from '@app/contexts/container-panel-context';
import { WasteStreamPanelContextProvider } from '@app/contexts/waste-stream-panel-context';
import { ServiceAreaProvider } from '@app/contexts/service-area-context';
import { SidebarProvider } from '@app/contexts/sidebar-context';
import { UserProvider } from '@app/contexts/user-context';
import { AppProvider } from '@app/contexts/app-context';

const GlobalContextProvider: FunctionComponent = ({ children }) => (
  <AppProvider>
    <UserProvider>
      <SidebarProvider>
        <VendorPanelModalProvider>
          <WasteStreamPanelContextProvider>
            <ContainerPanelProvider>
              <ServiceAreaProvider>{children}</ServiceAreaProvider>
            </ContainerPanelProvider>
          </WasteStreamPanelContextProvider>
        </VendorPanelModalProvider>
      </SidebarProvider>
    </UserProvider>
  </AppProvider>
);

export default GlobalContextProvider;
