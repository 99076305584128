import { useMemo, ButtonHTMLAttributes, ReactNode } from 'react';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  className?: string;
  buttonType?:
  | 'primary'
  | 'bg-primary-low-em'
  | 'bg-light-primary'
  | 'secondary'
  | 'base'
  | 'warning'
  | 'success'
  | 'success-light-disabled'
  | 'error'
  | 'info'
  | 'text'
  full?: boolean;
  startIcon?: ReactNode;
  trailingIcon?: ReactNode;
  size?:
  | 'xs'
  | 'sm'
  | 'base'
  | 'l'
  | 'xl';
}

const Button = ({
  buttonType = 'primary',
  size = 'base',
  className = '',
  full,
  children,
  startIcon,
  trailingIcon,
  ...otherProps
}: ButtonProps) => {
  const buttonBgColor = useMemo(() => {
    switch (buttonType) {
      case 'secondary':
        return `bg-base text-primary dark:text-light hover:bg-background-stroke
        dark:bg-background-low-em-dark dark:hover:bg-background-mid-em-dark`;
      case 'base':
        return 'bg-base text-white';
      case 'info':
        return 'bg-info text-white';
      case 'warning':
        return 'bg-orange-500 text-white';
      case 'success':
        return 'bg-secondary text-white';
      case 'error':
        return 'bg-red-500 text-white';
      case 'bg-primary-low-em':
        return 'bg-primary-low-em text-primary';
      case 'bg-light-primary':
        return `bg-light dark:bg-background-low-em-dark dark:border dark:border-background-stroke-dark text-primary 
      drop-shadow-sm hover:bg-base-light dark:hover:bg-background-stroke-dark`;
      case 'success-light-disabled':
        return 'disabled:!bg-secondary-low-em disabled:!text-secondary-light';
      case 'text':
        return `bg-transparent text-text-color-mid-em dark:text-text-color-low-em
        shadow-none focus:ring-0 focus:ring-offset-0`;
      case 'primary':
      default:
        return 'bg-primary text-white hover:bg-primary-dark';
    }
  }, [buttonType]);

  const buttonBorder = useMemo(() => {
    switch (buttonType) {
      case 'secondary': return `border border-background-stroke dark:border-background-stroke-dark
      disabled:border-transparent dark:disabled:border-transparent`;
      case 'base':
      case 'info':
      case 'warning':
      case 'success':
      case 'error':
      case 'bg-primary-low-em':
      case 'bg-light-primary':
      case 'primary':
      default: return 'border-transparent';
    }
  }, [buttonType]);

  const buttonFont = useMemo(() => {
    switch (size) {
      case 'xs': return 'btn-text-xs';
      case 'sm': return 'btn-text-sm';
      case 'base': return 'btn-text-base';
      case 'l':
      case 'xl': return 'btn-text-lg';
      default: return 'btn-text-base';
    }
  }, [buttonType]);

  const buttonPadding = useMemo(() => {
    switch (size) {
      case 'xs':
      case 'sm': return 'px-3 py-2';
      case 'base':
      case 'l': return 'px-4 py-2';
      case 'xl': return 'px-6 py-4';
      default: return 'px-4 py-2';
    }
  }, [buttonType]);

  const buttonRadius = useMemo(() => {
    switch (size) {
      case 'xs':
      case 'sm': return 'rounded-lg';
      case 'base':
      case 'l':
      case 'xl':
      default: return 'rounded-xl';
    }
  }, [buttonType]);

  const isFull = full && 'w-full';
  const defaultClassStyles = `${buttonRadius} flex items-center justify-center shadow-sm disabled:cursor-not-allowed
   focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-cyan-600
   disabled:text-text-color-disabled disabled:shadow-none
   disabled:bg-background-mid-em dark:disabled:bg-background-disabled-dark`;
  const renderStartIcon = startIcon == null ? null : (<span className="mr-2">{startIcon}</span>);
  const renderTrailingIcon = trailingIcon == null ? null : (<span className="ml-2">{trailingIcon}</span>);

  return (
    <button
      type="button"
      className={
        `${className} ${defaultClassStyles} ${buttonBgColor} ${buttonFont} ${buttonPadding} ${buttonBorder} ${isFull}`
      }
      {...otherProps}
      disabled={buttonType === 'success-light-disabled' || otherProps.disabled}
    >
      {renderStartIcon}
      {children}
      {renderTrailingIcon}
    </button>
  );
};

export default Button;
