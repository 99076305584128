import Icon from '@mdi/react';
import { mdiInformation } from '@mdi/js';

export type DisabledContainerInfoType = {
  size?: 'xs' | 'md'
}
const DisabledContainerInfo = ({ size = 'xs' } : DisabledContainerInfoType) => (
  <div className="flex items-center">
    <span className={`mr-1 ${size === 'xs' ? 'btn-text-xs' : 'btn-text-md'}`}>
      Select type first
    </span>
    <span className={size === 'xs' ? '' : 'pt-1/2'}>
      <Icon path={mdiInformation} size={0.6} />
    </span>
  </div>
);

export default DisabledContainerInfo;
