import { useAppContext } from '@app/contexts/app-context';
import { useThemeContext } from '@app/contexts/theme-context';
import { useUserContext } from '@app/contexts/user-context';
import {
  ALL_SETUP,
  FEATURE_CONTAINER, FEATURE_SERVICE_AREA, FEATURE_WASTESTREAM, GETTING_STARTED, SERVICE_OFFERING_INTRO,
} from '@app/contexts/user-context/constants';
import { useMemo } from 'react';
import Modal from '../modal';
import AllSetup from './all-setup';
import GettingStarted from './getting-started';
import ServiceOfferingSection from './service-offering-section';

const OnboardingModal = () => {
  const { isOpenOnboardingModal, closeOnboardingModal } = useAppContext();
  const { onboardingSteps } = useUserContext();
  const { isMobile, isTablet } = useThemeContext();
  const allSteps = [
    GETTING_STARTED.key,
    SERVICE_OFFERING_INTRO.key,
    FEATURE_CONTAINER.key,
    FEATURE_SERVICE_AREA.key,
    FEATURE_WASTESTREAM.key,
  ];

  const currentStep = useMemo(() => {
    if (allSteps.every((k) => onboardingSteps.includes(k)) && !onboardingSteps.includes(ALL_SETUP.key)) {
      return <AllSetup />;
    }
    if (onboardingSteps.includes(GETTING_STARTED.key)) return <ServiceOfferingSection />;
    return <GettingStarted />;
  }, [onboardingSteps]);

  return (
    <Modal
      isOpen={isOpenOnboardingModal}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      onRequestClose={closeOnboardingModal}
      overrideStyles={{
        content: {
          padding: 0,
          // eslint-disable-next-line no-nested-ternary
          width: isMobile ? '90vw' : isTablet ? '80vw' : 'auto',
          maxHeight: '90vh',
        },
      }}
    >
      {currentStep}
    </Modal>
  );
};

export default OnboardingModal;
