import {
  useState,
  useEffect,
  Fragment,
  FunctionComponent,
  useMemo,
  useRef,
} from 'react';
import {
  mdiChevronRight,
  mdiCloseThick, mdiMapCheck, mdiPlusThick, mdiSquareEditOutline, mdiTrashCan,
} from '@mdi/js'; import Modal from '@app/components/modal';
import { useVendorPanelModalContext } from '@app/contexts/vendor-panel-modal-context';
import { useServiceAreaContext } from '@app/contexts/service-area-context';
import { ServiceArea, ServiceAreaType } from '@app/contexts/service-area-context/types';
import VendorServiceAreaDeleteConfirmationModal from '@app/components/vendor-service-area-delete-confirmation-modal';
import VendorServiceAreaEditModal from '@app/components//vendor-service-area-edit-modal';
import { Form } from 'react-final-form';
import FormFieldReactSelect from '@app/forms/fields/react-select';
import FormFieldInput from '@app/forms/fields/input';
import { isEmpty } from 'lodash';
import { useUserContext } from '@app/contexts/user-context';
import { useThemeContext } from '@app/contexts/theme-context';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Icon from '@mdi/react';
import Button from './button';
import ServiceAreaTypeToggler from './service-areas/service-area-type-toggler';
import AddressSearch from './service-areas/address-search';

export type VendorServiceAreasListProp = {
  isOnboarding?: boolean;
};
const VendorServiceAreasList: FunctionComponent<VendorServiceAreasListProp> = ({
  isOnboarding = false,
}) => {
  const {
    serviceAreas,
    setSelectedServiceArea,
    listServiceAreas,
    listZipCodes,
    addServiceAreaToVendor,
  } = useServiceAreaContext();
  const formRef = useRef(null);
  const { currentUser } = useUserContext();
  const {
    isServiceAreaTableAddMode,
    setIsServiceAreaTableAddMode,
    closeServiceAreaTable,
    isOpenServiceAreaTable,
    openServiceAreaTable,
  } = useVendorPanelModalContext();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [serviceAreaType, setServiceAreaType] = useState<ServiceAreaType>('zip');
  const [addressSearchKeyword, setAddressSearchKeyword] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isAddingNew, setIsaddingNew] = useState(isOnboarding);
  const { isMobile, isTablet } = useThemeContext();
  const isServiceAreaZip = serviceAreaType === 'zip';

  useEffect(() => {
    const take = 10;
    const init = async () => {
      if (currentUser?.vendor?.id == null) return;
      const results = await listServiceAreas({
        vendorId: currentUser.vendor.id,
        page,
        take,
        append: true,
      });
      setHasMore(!(results.length < take));
    };
    if (page === 1) return;
    init();
  }, [page, currentUser?.vendor?.id]);

  useEffect(() => {
    if (!isServiceAreaTableAddMode) return;
    setIsaddingNew(true);
  }, [isServiceAreaTableAddMode]);

  const closeAndResetModal = () => {
    closeServiceAreaTable();
    setIsServiceAreaTableAddMode(false);
    setIsaddingNew(false);
    setAddressSearchKeyword('');
    setServiceAreaType('zip');
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (!(bottom && hasMore)) return;
    setPage((prev) => prev + 1);
  };

  const handleEdit = (serviceArea: ServiceArea) => {
    setSelectedServiceArea(serviceArea);
    setEditDialogOpen(true);
  };

  const handleDelete = (serviceArea: ServiceArea) => {
    setSelectedServiceArea(serviceArea);
    setDeleteDialogOpen(true);
  };

  async function loadOptions(search: string) {
    if (currentUser?.vendor?.id == null) return { options: [], hasMore: false };
    const zipCodes = await listZipCodes(search, currentUser.vendor.id);

    return {
      options: zipCodes.map((sa) => ({
        label: `${sa.zip} ${sa.city}, ${sa.state}`,
        value: sa.id,
      })),
      hasMore: false,
    };
  }

  const handleOnSubmit = async ({
    radius, serviceArea, address,
  }) => {
    try {
      if (currentUser?.vendor?.id == null) return;
      if (isServiceAreaZip) {
        addServiceAreaToVendor({
          vendorId: currentUser.vendor.id,
          type: serviceAreaType,
          zipCodeId: serviceArea.value,
          radius: null,
        });
      } else {
        const geoCode = await geocodeByAddress(address);
        const addressPlace = geoCode[0];
        const latLng = await getLatLng(addressPlace);
        const zipItem = addressPlace?.address_components.find((c) => c.types.includes('postal_code'));
        const zipResults = zipItem == null ? [] : await listZipCodes(zipItem.long_name);
        const addressZipCode = zipResults[0];

        addServiceAreaToVendor({
          vendorId: currentUser.vendor.id,
          type: serviceAreaType,
          radius: +radius,
          address1: address,
          zipCodeId: addressZipCode == null ? null : addressZipCode.id,
          addressLat: latLng.lat,
          addressLng: latLng.lng,
        });
      }

      setIsaddingNew(isOnboarding);
      setAddressSearchKeyword('');
      if (formRef.current == null || !isOnboarding) return;
      formRef.current.reset();
    } catch (error) {
      console.error(error);
      if (formRef.current == null) return;
      formRef.current.reset();
      setAddressSearchKeyword('');
    }
  };

  const renderForm = useMemo(
    () => (
      <Form
        onSubmit={handleOnSubmit}
        render={({
          handleSubmit, submitting, values, form,
        }) => (
          <form onSubmit={handleSubmit}>
            <div
              className={`flex md:space-x-1 text-xs
           md:items-center  rounded-2xl justify-center
           flex-col md:flex-row py-1`}
            >
              <div className="w-full -mt-1.5 mb-1 md:mb-0 text-sm">
                {isServiceAreaZip ? (
                  <FormFieldReactSelect
                    // eslint-disable-next-line react/jsx-no-bind
                    overrideLoadOptions={loadOptions}
                    apiUrl="/service-area"
                    name="serviceArea"
                    label=""
                    placeholder={
                      <span className="text-body-sm">Search zip codes</span>
                    }
                    required
                    initialValue={null}
                  />
                ) : (
                  <div className="flex flex-col md:flex-row items-center gap-x-1 ">
                    <div className="w-full">
                      <AddressSearch
                        value={addressSearchKeyword}
                        onChange={(v) => {
                          setAddressSearchKeyword(v);
                          form.change('address', null);
                        }}
                        onSelect={(e) => {
                          setAddressSearchKeyword(e);
                          form.change('address', e);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              {!isServiceAreaZip && (
              <div className="-mt-1.5 mb-1 md:mb-0">
                <FormFieldInput
                  name="radius"
                  label=""
                  type="number"
                  min={1}
                  required
                  placeholder="Radius (miles)"
                />
              </div>
              )}
              <div className="flex flex-row space-x-2">
                <Button
                  type="submit"
                  size="xs"
                  disabled={
                    isEmpty(values)
                    || (isServiceAreaZip && isEmpty(values.serviceArea))
                    || (!isServiceAreaZip
                      && (isEmpty(values.radius)
                        || values.radius < 1
                        || isEmpty(values.address)))
                    || submitting
                  }
                  full={isMobile}
                  onClick={() => {
                    formRef.current = form;
                  }}
                >
                  {submitting ? 'Saving..' : 'Add'}
                </Button>
                {!isOnboarding && (
                <Button
                  buttonType="secondary"
                  size="xs"
                  full={isMobile}
                  onClick={() => setIsaddingNew(false)}
                >
                  <Icon path={mdiCloseThick} size={0.6} />
                </Button>
                )}
              </div>
            </div>
          </form>
        )}
      />
    ),
    [
      isOnboarding,
      isMobile,
      currentUser?.vendor?.id,
      serviceAreaType,
      addressSearchKeyword,
    ],
  );

  const renderList = useMemo(
    () => (
      <div>
        <div className="p-2 md:p-3">
          <div
            className={`flex ${
              isAddingNew ? 'flex-col sm:flex-row' : 'flex-row'
            } w-full justify-between`}
          >
            {isOnboarding ? (
              <div className="text-left pb-1">
                <div className="pb-3">
                  <Icon
                    path={mdiMapCheck}
                    size={1.5}
                    className="text-secondary"
                  />
                </div>
                <span className="label-lg md:h3 text-primary-dark dark:text-primary-light">
                  Add Service Area
                </span>
                <br />
                <span>
                  To ensure the reach of your service, add an area and the
                  radius of your reach.
                </span>
              </div>
            ) : (
              <div className="flex flex-row items-center space-x-3 pl-2">
                <Icon path={mdiMapCheck} size={1} className="text-secondary" />
                <h4>Service Areas</h4>
              </div>
            )}
            {currentUser.allowed.canEditServiceArea && !isAddingNew && (
              <div>
                <div className="xs:hidden">
                  <Button
                    buttonType="secondary"
                    onClick={() => setIsaddingNew(true)}
                    className="dark:!bg-background-mid-em-dark"
                    size="xs"
                  >
                    <Icon path={mdiPlusThick} size={0.6} />
                  </Button>
                </div>
                <div className="hidden xs:block">
                  <Button
                    startIcon={<Icon path={mdiPlusThick} size={0.6} />}
                    buttonType="secondary"
                    onClick={() => setIsaddingNew(true)}
                    className="dark:!bg-background-mid-em-dark"
                    size="xs"
                  >
                    Add New
                  </Button>
                </div>
              </div>
            )}
          </div>
          {!isOnboarding && (
            <div className="w-full bg-background-stroke h-px my-4 dark:bg-background-stroke-dark" />
          )}
          {isAddingNew && currentUser.allowed.canEditServiceArea && (
            <Fragment>
              <div className="mb-2 flex justify-center">
                <ServiceAreaTypeToggler
                  selectedType={serviceAreaType}
                  onSelectType={(t) => setServiceAreaType(t)}
                />
              </div>
              {renderForm}
            </Fragment>
          )}
          <div
            className="label-sm-bold mt-4  bg-background-low-em border-b-2 text-text-color-mid-em
          dark:bg-background-stroke-dark
          dark:border-background-stroke-dark
          dark:text-text-color-low-em"
          >
            <div className="grid grid-cols-12 py-2 px-4">
              <div
                className={`${isOnboarding ? 'col-span-7' : 'col-span-5'} 
                flex items-center`}
              >
                Area
              </div>
              <div className="col-span-3">Radius</div>
              {!isOnboarding && currentUser.allowed.canEditServiceArea && (
                <div className="col-span-2 flex justify-center sm:block">
                  Edit
                </div>
              )}
              {currentUser.allowed.canDeleteServiceArea && (
                <div className="col-span-2 flex justify-center sm:pl-2 sm:block">
                  Delete
                </div>
              )}
            </div>
          </div>
          <div
            style={{ maxHeight: isOnboarding ? '25vh' : 350 }}
            className="overflow-auto mb-3"
            onScroll={handleScroll}
          >
            {isEmpty(serviceAreas) && <small>No Service Area found...</small>}
            {serviceAreas.map((item) => (
              <div
                key={item.id}
                className="text-body-sm text-text-color-mid-em"
              >
                <div className="grid grid-cols-12 p-2 ">
                  <div
                    className={`
                    ${isOnboarding ? 'col-span-7' : 'col-span-5'}
                    flex items-center truncate mr-3`}
                  >
                    {item.type === 'address'
                      ? item.address1
                      : `${item.zipCode.zip} ${item.zipCode.city}, ${item.zipCode.state}`}
                  </div>
                  <div
                    className={`flex col-span-3 ${
                      isOnboarding ? 'justify-center' : ''
                    } items-center`}
                  >
                    {item.radius == null
                      ? 'N/A'
                      : `${item.radius} mile${item.radius > 1 ? 's' : ''}`}
                  </div>
                  {!isOnboarding && currentUser.allowed.canEditServiceArea && (
                    <div className="col-span-2 flex justify-center sm:block text-lg text-primary">
                      {item.type === 'address' && (
                        <button type="button" onClick={() => handleEdit(item)}>
                          <Icon path={mdiSquareEditOutline} size={0.6} />
                        </button>
                      )}
                    </div>
                  )}
                  {currentUser.allowed.canDeleteServiceArea && (
                    <div className="col-span-2 flex justify-center sm:pl-2 sm:block text-lg text-primary">
                      <button type="button" onClick={() => handleDelete(item)}>
                        <Icon path={mdiTrashCan} size={0.6} />
                      </button>
                    </div>
                  )}
                </div>
                <div className="w-full bg-background-stroke h-px dark:bg-background-stroke-dark" />
              </div>
            ))}
          </div>
        </div>
        {!isOnboarding && (
          <div className="text-right p-3">
            <Button
              buttonType="secondary"
              onClick={closeAndResetModal}
              className="dark:!bg-transparent"
              size="xs"
            >
              Close
            </Button>
          </div>
        )}
      </div>
    ),
    [
      isOnboarding,
      isAddingNew,
      currentUser.allowed.canEditServiceArea,
      currentUser.allowed.canDeleteServiceArea,
      serviceAreas,
      renderForm,
      serviceAreaType,
    ],
  );

  return (
    <Fragment>
      {isOnboarding ? (
        renderList
      ) : (
        <Modal
          isOpen={isOpenServiceAreaTable}
          onRequestClose={closeAndResetModal}
          overrideStyles={{
            content: {
              width: isMobile ? '90vw' : '70vw',
              maxHeight: '70vh',
              padding: isMobile ? 0 : 'auto',
            },
          }}
        >
          {renderList}
        </Modal>
      )}
      <VendorServiceAreaDeleteConfirmationModal
        isOpen={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      />
      <VendorServiceAreaEditModal
        isOpen={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
      />
      {!isOnboarding && (
        <button
          type="button"
          className="bg-light drop-shadow-md p-2 rounded-lg flex justify-center
        dark:bg-background-main-dark dark:border-2 dark:border-background-stroke-dark"
          onClick={openServiceAreaTable}
        >
          <span className="text-primary mx-1 flex flex-row items-center">
            {!isTablet && (
            <span>
              View All
              {' '}
            </span>
            )}
            <Icon className={isTablet ? 'ml-1' : 'ml-2'} size={0.7} path={mdiChevronRight} />
          </span>
        </button>
      )}
    </Fragment>
  );
};

export default VendorServiceAreasList;
