import Button from '@app/components/button';
import Modal from '@app/components/modal';
import { Form } from 'react-final-form';
import DisabledContainerInfo from '@app/components/disabled-container-info';
import { useVendorPanelModalContext } from '@app/contexts/vendor-panel-modal-context';
import { useContainerPanelContext } from '@app/contexts/container-panel-context/index';
import { useThemeContext } from '@app/contexts/theme-context';
import { useUserContext } from '@app/contexts/user-context';
import { isEmpty } from 'lodash';
import { ContainerSize } from '@app/contexts/container-panel-context/types';
import FormFieldReactSelect from './fields/react-select';

export const changePasswordValidate = (values) => values;

type ContainerTypeFormData = {
  value: number;
  label: string;
}

type ContainerSizeFormData = {
  containerType: ContainerTypeFormData;
  containerSizes: ContainerTypeFormData[]
}

const EditVendorContainerForm = ({ noSizeContainerSize } : { noSizeContainerSize: ContainerSize }) => {
  const { currentUser } = useUserContext();

  const {
    isEditContainerModalOpen,
    closeEditContainerModal,
  } = useVendorPanelModalContext();
  const { isMobile, isTablet } = useThemeContext();

  const {
    containerFormData,
    setContainerFormData,
    handleEditContainer,
  } = useContainerPanelContext();

  const closeAndResetModal = () => {
    closeEditContainerModal();
    setContainerFormData(null);
  };

  const handleOnSubmit = async (payload : ContainerSizeFormData) => {
    if (currentUser?.vendor?.id == null) return;
    const body = {
      vendorId: currentUser.vendor.id,
      containerTypeId: payload.containerType.value,
      containerSizeIds: payload.containerSizes.map((cs) => cs.value),
    };

    await handleEditContainer({ ...body, containerId: containerFormData.container.id });

    closeAndResetModal();
  };

  const isNoSizeContainerType = containerFormData != null
    && noSizeContainerSize.containerTypes.some((t) => t.id === containerFormData.container.containerType.id);
  const renderForm = (
    <Form
      onSubmit={handleOnSubmit}
      // validate={changePasswordValidate}
      initialValues={{
        containerSizes: containerFormData !== null
          ? containerFormData.container?.containerSizes?.map((cs) => ({ value: cs.id, label: cs.name }))
          : [],
        containerType: containerFormData !== null
          ? {
            value: containerFormData.container.containerType.id,
            label: containerFormData.container.containerType.name,
          }
          : {},
      }}
      render={({
        handleSubmit, submitting, values, form,
      }) => (
        <form onSubmit={handleSubmit}>
          <h4 className="mb-5">Edit Container</h4>
          <div className="grid grid-cols-1 gap-6 text-sm mb-10">
            <FormFieldReactSelect
              apiUrl="/options/container-type"
              name="containerType"
              label="Container Type"
              disabled
              required
            />
            <FormFieldReactSelect
              selectKey={values.containerType?.value}
              disabled={values.containerType?.value == null || isNoSizeContainerType}
              apiUrl={`/options/container-size/${values.containerType?.value}`}
              name="containerSizes"
              label="Container Size"
              placeholder={values.containerType?.value == null ? <DisabledContainerInfo /> : undefined}
              isMulti
              required
            />
          </div>
          <div className="flex space-x-3">
            <Button
              type="submit"
              disabled={submitting || values.containerType?.value == null || isEmpty(values.containerSizes)}
              full
              size="xs"
            >
              {submitting ? 'Saving..' : 'Save'}
            </Button>
            <Button
              buttonType="secondary"
              full
              onClick={closeAndResetModal}
              size="xs"
            >
              Cancel
            </Button>
          </div>
        </form>
      )}
    />
  );

  return (
    <Modal
      isOpen={isEditContainerModalOpen}
      overrideStyles={isMobile || isTablet ? {
        content: {
          width: isMobile ? '80vw' : '50vw',
        },
      } : {}}
      onRequestClose={closeAndResetModal}
    >
      {renderForm}
    </Modal>
  );
};

export default EditVendorContainerForm;
