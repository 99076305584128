import { useEffect, FunctionComponent } from 'react';
import Modal from '@app/components/modal';
import { useServiceAreaContext } from '@app/contexts/service-area-context';
import Button from '@app/components/button';

type Prop = {
  isOpen: boolean;
  onClose: () => void;
};

const ServiceAreaDeleteConfirmationModal: FunctionComponent<Prop> = ({
  isOpen,
  onClose,
}) => {
  const { selectedServiceArea, deleteServiceArea, setSelectedServiceArea } = useServiceAreaContext();
  useEffect(() => {
    if (isOpen && selectedServiceArea != null) return;
    onClose();
  }, [isOpen]);

  const handleDelete = () => {
    deleteServiceArea(selectedServiceArea);
    onClose();
    setSelectedServiceArea(null);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <div className="flex flex-col justify-between" style={{ height: 200 }}>
        <div>
          <h6 className="pb-4">
            <strong>Are you sure you want to delete this service area?</strong>
          </h6>
          <p>
            {selectedServiceArea?.zipCode != null ? `
            ${selectedServiceArea?.zipCode.zip}
            ${selectedServiceArea?.zipCode.city},
            ${selectedServiceArea?.zipCode.country},
            ${selectedServiceArea?.zipCode.state}` : 'TODO'}
          </p>
        </div>
        <div className="flex space-x-3">
          <Button
            full
            onClick={handleDelete}
          >
            Delete
          </Button>
          <Button full buttonType="secondary" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ServiceAreaDeleteConfirmationModal;
