import { OnboardingKeyItem } from './types';

export const GETTING_STARTED: OnboardingKeyItem = {
  key: 'GETTING_STARTED',
  order: 1,
};

export const SERVICE_OFFERING_INTRO: OnboardingKeyItem = {
  key: 'SERVICE_OFFERING_INTRO',
  order: 2,
};

export const FEATURE_SERVICE_AREA: OnboardingKeyItem = {
  key: 'FEATURE_SERVICE_AREA',
  order: 3,
};

export const FEATURE_CONTAINER: OnboardingKeyItem = {
  key: 'FEATURE_CONTAINER',
  order: 4,
};

export const FEATURE_WASTESTREAM: OnboardingKeyItem = {
  key: 'FEATURE_WASTESTREAM',
  order: 5,
};

export const ALL_SETUP: OnboardingKeyItem = {
  key: 'ALL_SETUP',
  order: 6,
};

export const APPROVED_USER: OnboardingKeyItem = {
  key: 'APPROVED',
  order: 7,
};

export const ONBOARDING_KEYS: OnboardingKeyItem[] = [
  GETTING_STARTED,
  SERVICE_OFFERING_INTRO,
  FEATURE_SERVICE_AREA,
  FEATURE_CONTAINER,
  FEATURE_WASTESTREAM,
  ALL_SETUP,
];
