import { useState, createContext, ReactNode } from 'react';

export const SidebarContext = createContext(null);

export interface SidebarProps {
  children: ReactNode,
}

export const SidebarProvider = ({ children }: SidebarProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <SidebarContext.Provider value={{ isExpanded, setIsExpanded }}>
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarProvider;
