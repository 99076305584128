import {
  useState,
  createContext,
  useEffect,
  ReactNode,
  useContext,
} from 'react';
import { extractedTailwindTheme } from '@app/lib/helpers';
import { inRange } from 'lodash';
import { WidgetConfig } from '@livechat/widget-react';

export const ThemeContext = createContext(null);

const variants = ['light', 'dark'];
export type themeType = 'light' | 'dark';

export interface ThemeProviderProps {
  initialTheme?: themeType,
  children: ReactNode,
}

export const ThemeProvider = ({ initialTheme, children }: ThemeProviderProps) => {
  const [theme, setTheme] = useState(initialTheme);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [liveChatVisiblity, setLiveChatVisiblity] = useState<WidgetConfig['visibility']>('hidden');

  const handleThemeChange = (newTheme: themeType) => {
    window.localStorage.setItem('theme', newTheme);

    const root = window.document.documentElement;
    const isDark = newTheme === 'dark';

    root.classList.remove(isDark ? 'light' : 'dark');
    root.classList.add(newTheme);

    setTheme(newTheme);
  };

  const setScreenType = () => {
    const screensize = window.screen.width;
    const tabletBreakpoint = +extractedTailwindTheme.screens.md.replace(
      'px',
      '',
    );
    const desktopBreakpoint = +extractedTailwindTheme.screens.lg.replace(
      'px',
      '',
    );
    setIsMobile(screensize < tabletBreakpoint);
    setIsTablet(inRange(screensize, desktopBreakpoint, tabletBreakpoint));
  };

  const handleLiveChatVisibilityToggle = () => {
    if (liveChatVisiblity === 'hidden' || liveChatVisiblity === 'minimized') {
      setLiveChatVisiblity('maximized');
    } else {
      setLiveChatVisiblity('hidden');
    }
  };

  /**
   * Analyze on fist mount
   */
  useEffect(() => {
    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const localTheme = window.localStorage.getItem('theme');

    if (variants.includes(localTheme)) {
      handleThemeChange(localTheme as themeType);
    } else if (isDarkMode) {
      handleThemeChange('dark');
    } else {
      handleThemeChange(initialTheme);
    }
    // useEffect only renders on client side, window assured to be not undefined
    setScreenType();
  }, []);

  if (typeof window !== 'undefined') {
    window.addEventListener('resize', () => {
      setScreenType();
    });
  }

  return (
    <ThemeContext.Provider
      value={{
        theme,
        handleThemeChange,
        isMobile,
        isTablet,
        handleLiveChatVisibilityToggle,
        liveChatVisiblity,
        setLiveChatVisiblity,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

const useThemeContext = () => useContext(ThemeContext);

export default ThemeProvider;

export { useThemeContext };
