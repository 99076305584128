import { useThemeContext } from '@app/contexts/theme-context';
import { useUserContext } from '@app/contexts/user-context';
import { LiveChatWidget, WidgetConfig } from '@livechat/widget-react';
import { useEffect } from 'react';

export const ReactLiveChat = (props: Omit<WidgetConfig, 'license'>) => (
  <LiveChatWidget
    license={process.env.NEXT_PUBLIC_LIVE_CHAT_WIDGET_ID}
    // visibility="minimized"
    {...props}
  />
);

export const ReactLiveChatAuthenticated = () => {
  const { isMobile, setLiveChatVisiblity, liveChatVisiblity } = useThemeContext();
  const { currentUser } = useUserContext();

  const {
    fullName, email, vendor, id, primaryPhone,
  } = currentUser;
  const sessionVariables = {
    id, fullName, email, vendorName: vendor.name, phone: primaryPhone, vendorWebsite: vendor.website,
  };

  const handleVisiblityChange = ({ visibility }) => {
    if (visibility === 'minimized' && isMobile) {
      setLiveChatVisiblity('hidden');
    }
  };

  useEffect(() => {
    if (!isMobile) { setLiveChatVisiblity('minimized'); }
  }, []);

  return (
    <ReactLiveChat
      customerEmail={email}
      customerName={fullName}
      visibility={liveChatVisiblity}
      onVisibilityChanged={handleVisiblityChange}
        // It seem to only accept 1 record but according
        // to the documentation. It accepts an object.
        // They probably got wrong here in type
      sessionVariables={sessionVariables as any}
    />

  );
};
