// import Image from 'next/image';
import Button from '@app/components/button';
import { useUserContext } from '@app/contexts/user-context';
import { GETTING_STARTED } from '@app/contexts/user-context/constants';
import { useAppContext } from '@app/contexts/app-context';
import { useThemeContext } from '@app/contexts/theme-context';
import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js';

const GettingStarted = () => {
  const { addOnboardingStep } = useUserContext();
  const { isLoading } = useAppContext();
  const { theme } = useThemeContext();
  const isDark = theme === 'dark';

  return (
    <div>
      <div className="px-5 pt-5 md:px-20 md:pt-20 bg-primary flex flex-col gap-y-16">
        <div className="flex flex-col text-center text-light">
          <span className="label-md md:label-lg">Getting started</span>
          <span className="h4 md:h2 pb-5 py-2">Welcome to SimpleWaste!</span>
          <span className="text-body-lg">
            Hi there! Thank you for signing up. We’re here to help you through
            the process of setting up your vendor profile and service offerings.
            <span className="label-lg"> Click next and let’s get started!</span>
          </span>
        </div>
        {isDark
          ? (
            <img
              className="rounded-tl-xl rounded-tr-xl"
              alt="intro-banner"
              src="images/onboarding/getting-started-dark.png"
            />
          )
          : (
            <img
              className="rounded-tl-xl rounded-tr-xl"
              alt="intro-banner"
              src="images/onboarding/getting-started-light.png"
            />
          )}
      </div>
      <div className="bg-primary-dark dark:bg-primary-light py-4 px-6 flex flex-row justify-end">
        <Button
          onClick={() => addOnboardingStep(GETTING_STARTED.key)}
          buttonType="secondary"
          trailingIcon={<Icon path={mdiChevronRight} size={1} />}
          disabled={isLoading('handleAddOnboardingStep')}
        >
          {isLoading('handleAddOnboardingStep') ? 'Proceeding' : 'Next'}
        </Button>
      </div>
    </div>
  );
};

export default GettingStarted;
