import { ThemeContext } from '@app/contexts/theme-context';
import { Fragment, useContext } from 'react';
import dynamic from 'next/dynamic';
import { TooltipProps } from './tooltip';

const ReactTooltip = dynamic(() => import('react-tooltip'), {
  ssr: false,
});

const TooltipContaienr = () => {
  const { theme } = useContext(ThemeContext);
  const isDark = theme === 'dark';

  const config: Partial<TooltipProps> = {
    place: 'right',
    type: isDark ? 'dark' : 'light',
    effect: 'solid',
    className: 'max-w-xs shadow-md',
    delayHide: 500,
    clickable: true,
  };

  return (
    <Fragment>
      {/* Global target */}
      <ReactTooltip
        {...config}
      />
      {/* Used to target tooltip fpr info(renders at bottom) */}
      <ReactTooltip
        {...config}
        place="bottom"
        id="info"
        getContent={(dataTip) => (
          <p>
            <b>About:</b>
            {' '}
            {dataTip}
          </p>
        )}
      />
    </Fragment>
  );
};

export default TooltipContaienr;
