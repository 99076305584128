import {
  createContext,
  FunctionComponent,
  useContext,
  useReducer,
  useEffect,
  useMemo,
} from 'react';
import { useSession } from 'next-auth/react';
import {
  UserContextType,
  User,
  OnboardingKeyType,
} from '@app/contexts/user-context/types';
import { frontendApi } from '@app/lib/api';
import { isUserApproved, keysToCamelCase } from '@app/lib/helpers';
import reducer, {
  initialState,
  setCurrentUser,
  setOnboardingSteps,
} from './user';
import { useAppContext } from '../app-context';
import { APPROVED_USER } from './constants';

const UserContext = createContext<UserContextType | any>({});

const UserProvider: FunctionComponent = ({ children }) => {
  const { status } = useSession();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { addLoading, removeLoading, openOnboardingModal } = useAppContext();

  const convertStringToDate = (o: any) => ({
    ...o,
    createdAt: o.createdAt != null ? new Date(o.createdAt) : null,
    updatedAt: o.updatedAt != null ? new Date(o.updatedAt) : null,
  });

  const getCurrentUser = async () => {
    addLoading('getCurrentUser');
    const response = await frontendApi.get('auth/me').finally(() => removeLoading('getCurrentUser'));
    if (response.data == null) return null;
    const currentUser: User = convertStringToDate(
      keysToCamelCase({
        ...response.data,
        vendor: convertStringToDate(keysToCamelCase(response.data.vendor)),
        statuses: keysToCamelCase(response.data.statuses),
      }),
    );
    setCurrentUser(dispatch)(currentUser);
    setOnboardingSteps(dispatch)(currentUser.onboardingSteps);
    if (currentUser.onboardingSteps.filter((k) => k !== APPROVED_USER.key).length < 6) {
      openOnboardingModal();
    }
    return currentUser;
  };

  const handleAddOnboardingStep = async (step: OnboardingKeyType) => {
    addLoading('handleAddOnboardingStep');
    const response = await frontendApi.post('auth/onboarding-steps', { step })
      .finally(() => removeLoading('handleAddOnboardingStep'));
    if (response.data === null) return;
    const { onboardingSteps } = keysToCamelCase(response.data);
    setOnboardingSteps(dispatch)(onboardingSteps);
  };

  const handleRemoveOnboardingStep = async (step: OnboardingKeyType) => {
    addLoading('handleRemoveOnboardingStep');
    const response = await frontendApi.delete(`auth/onboarding-steps/${step}`)
      .finally(() => removeLoading('handleRemoveOnboardingStep'));
    if (response.data === null) return;
    const { onboardingSteps } = keysToCamelCase(response.data);
    setOnboardingSteps(dispatch)(onboardingSteps);
  };

  useEffect(() => {
    if (status !== 'authenticated') return;
    getCurrentUser();
  }, [status]);

  const isApproved = useMemo(() => state.currentUser != null && isUserApproved(state.currentUser), [state.currentUser]);
  const isNotInStepper = useMemo(() => state.onboardingSteps.includes(APPROVED_USER.key), [state.onboardingSteps]);

  const value: UserContextType = {
    ...state,
    setCurrentUser: setCurrentUser(dispatch),
    addOnboardingStep: handleAddOnboardingStep,
    removeOnboardingStep: handleRemoveOnboardingStep,
    isApproved,
    isNotInStepper,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useUserContext = () => useContext<UserContextType>(UserContext);

export { UserProvider, useUserContext };
