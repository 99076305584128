import { ReactNode, InputHTMLAttributes } from 'react';
import { Field } from 'react-final-form';
import MetaErrorHandler from './meta-error-handler';

export interface FormFieldInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string | ReactNode,
  name: string,
  blockClassName?: string
  initialValue?: string,
}

const FormFieldInput = ({
  label,
  name,
  initialValue,
  blockClassName = 'block',
  ...inputProps
}: FormFieldInputProps) => (
  <Field name={name} initialValue={initialValue}>
    {({ input, meta }) => (
      <label className={blockClassName}>
        <span
          className="label-md text-text-color-high-em
        dark:text-text-color-high-em-dark"
        >
          {label}
        </span>
        <input
          type="text"
          className="mt-2 block w-full text-xs sm:text-body-sm rounded-md bg-light shadow-sm
           border-background-stroke
           dark:border-background-stroke-dark
           text-text-color-mid-em
           dark:text-text-color-low-em
           dark:bg-background-main-dark focus:border-primary-light
           focus:bg-white focus:ring-0"
          {...input}
          {...inputProps}
        />
        <MetaErrorHandler meta={meta} />
      </label>
    )}
  </Field>
);

export default FormFieldInput;
