import {
  FunctionComponent,
  ReactNode,
  useMemo,
  ButtonHTMLAttributes,
} from 'react';

export interface Prop extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: ReactNode;
  buttonType?: 'ghost' | 'primary' | 'secondary' | 'skeleton';
  size?: 'auto' | 'sm' | 'base';
  roundedClass?: string;
}

const IconButton: FunctionComponent<Prop> = ({
  icon,
  buttonType = 'ghost',
  size = 'base',
  roundedClass = 'rounded-full',
  ...otherProps
}) => {
  const buttonBgColor = useMemo(() => {
    switch (buttonType) {
      case 'secondary':
        return `bg-white
            dark:bg-background-low-em-dark dark:hover:bg-background-mid-em-dark text-primary 
            border border-gray-300 dark:border-gray-500`;
      case 'skeleton':
        return 'bg-grayscale-input hover:bg-grayscale-line disabled:opacity-50';
      case 'ghost':
        return 'bg-none';
      case 'primary':
      default:
        return 'bg-primary hover:bg-primary-dark disabled:opacity-50 text-white';
    }
  }, [buttonType]);

  const buttonSize = useMemo(() => {
    switch (size) {
      case 'auto':
        return 'w-auto h-auto';
      case 'sm':
        return 'w-8 h-8';
      case 'base':
      default:
        return 'w-10 h-10';
    }
  }, [size]);

  const className = `${buttonBgColor} ${buttonSize} ${roundedClass} flex justify-center items-center`;
  return (
    <button aria-label="Icon Button" type="button" {...otherProps} className={className}>
      {icon}
    </button>
  );
};

export default IconButton;
