import Button from '@app/components/button';
import { APP_URL } from '@app/constants/default';
import { LOGIN_WITH_TOKEN_PAGE } from '@app/constants/routes';
import { useAppContext } from '@app/contexts/app-context';
import { useUserContext } from '@app/contexts/user-context';
import { APPROVED_USER } from '@app/contexts/user-context/constants';
import { frontendApi } from '@app/lib/api';
import { extractedTailwindTheme, refreshAccessToken } from '@app/lib/helpers';
import {
  mdiAlertCircle, mdiCheckBold, mdiChevronLeft, mdiCloseThick,
} from '@mdi/js';
import Icon from '@mdi/react';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { handleSignOut } from './auth-guard';
import { NavbarThemeText } from './navbar-theme';

export interface StatusType {
  userStatus: string,
  vendorStatus: string
}

export interface StatusMarkerType {
  targetStatusIndex: number
}

export interface StatusDetailsType {
  stepIndex: number,
  header: string,
  subHeader: string,
}

export interface StatusIllustrationType {
  image: string,
  header: string,
  subheader: string,
  subheaderTwo: string,
}

const StepperComponent = ({ userAndVendorStatus }: any) => {
  const [statusStep, setStatusStep] = useState<number>(1);
  const [hasResent, setHasResent] = useState<boolean>(false);
  const [resendButtonLabel, setResendButtonLabel] = useState<String>('Resend Email');
  const { addOnboardingStep } = useUserContext();
  const { isLoading } = useAppContext();
  const { currentUser } = useUserContext();
  const userEmail = currentUser.email;
  // eslint-disable-next-line no-restricted-globals
  const originUrl = typeof window !== 'undefined' ? location.origin : APP_URL;

  const resendEmailVerification = async () => {
    try {
      setHasResent(true);
      const { data } = await frontendApi.post('/auth/resend-email-verification',
        {
          userId: currentUser.id,
          contactEmail: userEmail,
          callbackUrl: `${originUrl}${LOGIN_WITH_TOKEN_PAGE}`,
        });

      setResendButtonLabel(data.message);
    } catch (err) {
      console.error(err);
      setHasResent(false);
    }
  };

  useEffect(() => {
    if (userAndVendorStatus) {
      if (userAndVendorStatus === 'verification') {
        setStatusStep(2);
        // check submit, circle verify
      }
      if (userAndVendorStatus === 'pending') {
        setStatusStep(3);
        // check verified and submit, cirle on application
      }
      if (userAndVendorStatus === 'approved' || userAndVendorStatus === 'rejected'
        || userAndVendorStatus === 'rejectedWitPendingEmail' || userAndVendorStatus === 'approvedWitPendingEmail') {
        setStatusStep(4);
        // check verified and submit,application review, good to go
      }
    }
  }, [userAndVendorStatus]);

  const StatusDetails = ({ stepIndex, header, subHeader }: StatusDetailsType) => (
    <div className="pt-px">
      <p className={`label-md md:label-lg ${statusStep === stepIndex
        ? 'text-primary' : 'text-text-color-high-em dark:text-text-color-high-em-dark'}`}
      >
        {header}
      </p>
      <p className={`text-body-md xs:text-body-lg ${statusStep === stepIndex
        ? 'text-primary' : 'text-text-color-high-em dark:text-text-color-mid-em'}`}
      >
        {subHeader}
      </p>
    </div>
  );

  const StatusIllustration = ({
    image, header, subheader, subheaderTwo,
  }: StatusIllustrationType) => (
    <div className="flex -mt-20 flex-col justify-center items-center space-y-5">
      <div className={`${userAndVendorStatus === 'pending' && '-mr-10'}`}>
        <img src={image} alt="status-illustration" />
      </div>
      <div className="flex flex-col space-y-3 text-center px-5">
        <p className="text-primary h3">
          {header}
        </p>
        {userAndVendorStatus === 'pending' && (
          <div className="bg-primary-light dark:bg-primary-dark rounded-lg py-2 flex justify-center items-center">
            <div className="flex flex-col text-sm sm:h6 text-light">
              <p>
                Company Name:
                <span className="font-bold">{` ${currentUser.vendor.name}`}</span>
              </p>
              <p>
                Full Name:
                <span className="font-bold">{` ${currentUser.fullName}`}</span>
              </p>
            </div>
          </div>
        )}
        <div className="flex flex-col text-body-lg text-text-color-mid-em-light dark:text-text-color-mid-em-dark">
          <div>
            {userAndVendorStatus === 'verification' ? (
              <p>
                To complete your application, you&apos;ll need to verify your email address.
                <span className="font-bold">{` (${userEmail})`}</span>
              </p>
            ) : <p>{subheader}</p>}
          </div>
          <p>
            {subheaderTwo}
          </p>
          {(header === 'Verification Sent!' || userAndVendorStatus === 'approvedWitPendingEmail'
            || userAndVendorStatus === 'rejectedWitPendingEmail'
            || userAndVendorStatus === 'approved') && (
              <div className="pt-4 flex justify-center">
                {userAndVendorStatus === 'approved'
                  ? (
                    <Button
                      disabled={isLoading('handleAddOnboardingStep')}
                      onClick={async () => {
                        await refreshAccessToken();

                        await addOnboardingStep(APPROVED_USER.key);
                      }}
                    >
                      {isLoading('handleAddOnboardingStep') ? 'Proceeding to Dashboard' : 'Proceed to Dashboard'}
                    </Button>
                  ) : (
                    <Button disabled={hasResent} buttonType="secondary" onClick={() => resendEmailVerification()}>
                      {resendButtonLabel}
                    </Button>
                  )}
              </div>
          )}
        </div>
      </div>
    </div>
  );

  const getStatusIcon = (targetStatusIndex) => {
    if (userAndVendorStatus === 'rejectedWitPendingEmail') {
      if (targetStatusIndex === 2) {
        return <Icon path={mdiAlertCircle} size={0.8} className="text-warning-dark" />;
      }

      if (targetStatusIndex === 4) {
        return <Icon path={mdiCloseThick} size={0.8} className="text-error" />;
      }
    }
    if (userAndVendorStatus === 'approvedWitPendingEmail') {
      if (targetStatusIndex === 2) {
        return <Icon path={mdiAlertCircle} size={0.8} className="text-warning-dark" />;
      }
      if (targetStatusIndex === 4) {
        return <Icon path={mdiCheckBold} size={0.8} className="text-secondary" />;
      }
    }
    return <Icon path={mdiCheckBold} size={0.8} className="text-secondary" />;
  };

  const CurrentStatusIllustration = () => {
    if (statusStep === 1) {
      return (
        <StatusIllustration
          image="/images/swbid-login-2.png"
          header="Submit Vendor Application"
          subheader="Submit company information"
          subheaderTwo="Send a request for application."
        />
      );
    } if (statusStep === 2) {
      return (
        <StatusIllustration
          image="/images/verification-sent.png"
          header="Verification Sent!"
          subheader={`To complete your application, you'll need to verify your email address (${userEmail}).`}
          subheaderTwo="Please check your inbox for a message from us."
        />
      );
    } if (statusStep === 3) {
      return (
        <StatusIllustration
          image="/images/checking-application.png"
          header="Checking Application"
          subheader="After reviewing your application, we will send an email regarding your application status."
          subheaderTwo="Please check your inbox for an alert."
        />
      );
    }

    if (statusStep === 4 && (userAndVendorStatus === 'rejected')) {
      return (
        <StatusIllustration
          image="images/rejected-status.png"
          header="Application Rejected!"
          subheader="Your application is Rejected"
          subheaderTwo="Unfortunately our system has rejected your application.
          If you think this decision has been made in error, please feel free to contact us at 888-xxx-xxxx."
        />
      );
    }
    if (statusStep === 4 && (userAndVendorStatus === 'approvedWitPendingEmail')) {
      return (
        <StatusIllustration
          image="images/rejected-status.png"
          header="Application Approved!"
          subheader="Unfortunately, you haven't verified your email yet."
          subheaderTwo="Please check your email for the verification letter."
        />
      );
    }
    if (statusStep === 4 && (userAndVendorStatus === 'rejectedWitPendingEmail')) {
      return (
        <StatusIllustration
          image="images/rejected-status.png"
          header="Application Rejected!"
          subheader="Your application is Rejected."
          subheaderTwo="Unfortunately our system has rejected your application.
          If you think this decision has been made in error, please feel free to contact us at 888-xxx-xxxx.
          Also, please check your email for verification letter."
        />
      );
    }
    return (
      <StatusIllustration
        image="/images/swbid-login-3.png"
        header="Application Approved!"
        subheader="You&apos;re good to go!"
        subheaderTwo="You can now log in to your account."
      />
    );
  };

  const StatusMarker = ({ targetStatusIndex }: StatusMarkerType) => (
    <div className={`h-9 w-9 rounded-full flex items-center justify-center ${statusStep < targetStatusIndex
      ? ('bg-back>ground-low-em dark:bg-background-low-em-dark')
      : ('bg-primary-low-em dark:bg-secondary-low-em')}
      ${statusStep === targetStatusIndex && 'dark:border-4 dark:border-gray-400'}
      dark:bg-background-low-em-dark`}
    >
      {
        (statusStep > targetStatusIndex || (statusStep === 4 && userAndVendorStatus !== 'rejected'))
          ? getStatusIcon(targetStatusIndex)
          : (
            <div
              className="h-3 w-3 bg-primary-light rounded-full"
              style={{
                backgroundColor: statusStep < targetStatusIndex
                  ? 'gray'
                  : extractedTailwindTheme.colors.primary.DEFAULT,
              }}
            />
          )
      }
    </div>
  );

  const StatusLine = ({ targetStatusIndex }: StatusMarkerType) => (
    <div
      className="h-10 w-[2px] self-center rounded-lg"
      style={{
        backgroundColor: statusStep < targetStatusIndex ? 'gray' : extractedTailwindTheme.colors.secondary.DEFAULT,
      }}
    />
  );

  return (
    <div className="flex flex-col xl:grid xl:grid-cols-11 xl:min-h-screen">
      <div className="col-span-5 flex flex-col md:p-24 p-10 space-y-10">
        <div className="space-y-7">
          <div className="flex flex-row space-x-1">
            <Image src="/images/logo.png" height="30" width="40" />
            <div className="flex flex-row items-center text-lg">
              <p>SIMPLE</p>
              <p className="font-bold">WASTE</p>
            </div>
          </div>
          <div className="space-y-3">
            <p className="h3 text-primary">Complete Sign Up Process</p>
            <p className="text-body-lg text-text-color-mid-em">
              Follow the steps to complete your registration process.
            </p>
          </div>
          <div className="flex flex-row space-x-3">
            <div className="flex flex-col md:space-y-2 space-y-6 xs:space-y-3">
              <StatusMarker targetStatusIndex={1} />
              <StatusLine targetStatusIndex={2} />
              <StatusMarker targetStatusIndex={2} />
              <StatusLine targetStatusIndex={3} />
              <StatusMarker targetStatusIndex={3} />
              <StatusLine targetStatusIndex={4} />
              <StatusMarker targetStatusIndex={4} />
            </div>
            <div className="flex flex-col md:space-y-11 space-y-16 xs:space-y-[49px]">
              <StatusDetails
                stepIndex={1}
                header="Submit Vendor Application"
                subHeader="Submit company information"
              />
              <StatusDetails
                stepIndex={2}
                header="Verify Email"
                subHeader="Confirm your email address"
              />
              <StatusDetails
                stepIndex={3}
                header=" Application Review"
                subHeader="We&apos;ll check your submitted application"
              />
              {(userAndVendorStatus === 'rejected' && statusStep === 4)
                || userAndVendorStatus === 'rejectedWitPendingEmail'
                ? (
                  <StatusDetails
                    stepIndex={4}
                    header="Rejected"
                    subHeader="Your application is rejected"
                  />
                )
                : (
                  <StatusDetails
                    stepIndex={4}
                    header="Approval"
                    subHeader="You&apos;re good to go!"
                  />
                )}
            </div>
          </div>
          {userAndVendorStatus !== 'approved'
            && (
              <div className="py-2">
                <Button onClick={() => handleSignOut()} startIcon={<Icon path={mdiChevronLeft} size={1} />}>
                  Back to Log In
                </Button>
              </div>
            )}
        </div>
      </div>
      <div className="pt-36 pb-16 xl:col-span-6 bg-primary-low-em
       items-center justify-center flex flex-col dark:bg-background-primary-dark"
      >
        <CurrentStatusIllustration />
        <div className="flex justify-start pt-6">
          <NavbarThemeText />
        </div>
      </div>
    </div>
  );
};

export default StepperComponent;
