import Script from 'next/script';

const GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_API_KEY;

const source = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;

const GooglePlacesScript = () => (
  <Script
    type="text/javascript"
    src={source}
    strategy="beforeInteractive"
  />
);

export default GooglePlacesScript;
