import PacmanLoader from 'react-spinners/PacmanLoader';

export interface LoaderProps {
    loading?: boolean,
}

const Loader = ({ loading = true }: LoaderProps) => (
  <section className="flex items-center justify-center h-screen">
    <PacmanLoader color="#16a34a" loading={loading} size={25} />
  </section>
);

export default Loader;
