export type ZipCode = {
  id: number;
  lat: number;
  lng: number;
  state: string;
  country: string;
  county: string;
  city: string;
  type: string;
  zip: string;
  createdBy: number,
  createdAt: Date,
  updatedBy: number | null,
  updatedAt: Date | null,
}

export type ServiceAreaType = 'zip' | 'address'

export type RawServiceArea = {
  vendorId: number,
  zipCodeId?: number,
  radius?: number,
  type: ServiceAreaType
  address1?: string;
  addressLat?: number;
  addressLng?: number;
}

export type ServiceArea = {
  id: number,
  zipCode: ZipCode,
  radius: number | null,
  address1: string | null;
  addressLat: number | null;
  addressLng: number | null;
  type: ServiceAreaType,
  createdBy: number,
  createdAt: Date,
  updatedBy: number | null,
  updatedAt: Date | null
}

export type ServiceAreaState = {
  zipCodes: ZipCode[],
  serviceAreas: ServiceArea[]
}

export type Context = ServiceAreaState & {
  listZipCodes: (search?: string, vendorId?: number) => Promise<ZipCode[]>
  listServiceAreas: (params: {
    vendorId: number,
    page?: number,
    take?: number,
    append?: boolean
  }) => Promise<ServiceArea[]>
  addServiceAreaToVendor: (body: RawServiceArea) => Promise<void>
  updateServiceArea: (params: RawServiceArea) => Promise<void>
  deleteServiceArea: (s: ServiceArea) => Promise<void>
  selectedServiceArea: ServiceArea | null
  setSelectedServiceArea: (v: ServiceArea) => void;
}

export const SET_ZIP_CODES = 'SET_ZIP_CODES';
export const SET_SERVICE_AREAS = 'SET_SERVICE_AREAS';
export const ADD_SERVICE_AREA = 'ADD_SERVICE_AREA';
export const UPDATE_SERVICE_AREA = 'UPDATE_SERVICE_AREA';
export const DELETE_SERVICE_AREA = 'DELETE_SERVICE_AREA';
export const APPEND_SERVICE_AREAS = 'APPEND_SERVICE_AREAS';

export type SetZipCodes = {
  type: typeof SET_ZIP_CODES,
  zipCodes: ZipCode[]
}

export type SetServiceAreas = {
  type: typeof SET_SERVICE_AREAS,
  serviceAreas: ServiceArea[]
}

export type AddServiceArea = {
  type: typeof ADD_SERVICE_AREA,
  serviceArea: ServiceArea
}

export type UpdateServiceArea = {
  type: typeof UPDATE_SERVICE_AREA,
  serviceArea: ServiceArea
}

export type DeleteServiceArea = {
  type: typeof DELETE_SERVICE_AREA,
  serviceArea: ServiceArea;
}

export type AppendServiceAreas = {
  type: typeof APPEND_SERVICE_AREAS,
  serviceAreas: ServiceArea[]
}

export type ServiceAreaActionTypes =
  | SetZipCodes
  | SetServiceAreas
  | AddServiceArea
  | UpdateServiceArea
  | DeleteServiceArea
  | AppendServiceAreas
