import { ELEMENT_ROOT_ID, Z_INDECES } from '@app/constants/default';
import { ThemeContext } from '@app/contexts/theme-context';
import { merge } from 'lodash';
import { useContext, ReactNode } from 'react';
import ReactModal, { Props as ReactModalProps, Styles } from 'react-modal';
import { extractedTailwindTheme } from '@app/lib/helpers';

export const defaultModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0.375rem',
    minWidth: '25vw',
    maxWidth: '60rem',
    minHeight: '25vh',
    // height: '90vh',
    overlfow: 'scroll',
  },
};

ReactModal.setAppElement(ELEMENT_ROOT_ID);

export interface ModalProps extends ReactModalProps {
  children: ReactNode,
  overrideStyles?: Styles,
}

const Modal = ({
  children, overrideStyles, ...modalProps
}: ModalProps) => {
  const { theme } = useContext(ThemeContext);
  const isDark = theme === 'dark';

  const customModalStyles = {
    overlay: {
      backgroundColor: isDark ? 'rgba(0, 0, 0, 0.50)' : 'rgba(0, 0, 0, 0.25)',
      zIndex: Z_INDECES.MODAL,
    },
    content: {
      ...defaultModalStyles.content,
      borderColor: isDark ? extractedTailwindTheme.colors.gray[800] : extractedTailwindTheme.colors.gray[300],
      backgroundColor: isDark ? extractedTailwindTheme.colors.background.main.dark
        : extractedTailwindTheme.colors.base.DEFAULT,
    },
  };

  return (
    <ReactModal
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      style={merge(customModalStyles, overrideStyles)}
      {...modalProps}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
