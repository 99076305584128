import { Dispatch } from 'react';
import {
  ServiceAreaState,
  ServiceAreaActionTypes,
  SetZipCodes,
  SetServiceAreas,
  AddServiceArea,
  UpdateServiceArea,
  DeleteServiceArea,
  AppendServiceAreas,
  SET_ZIP_CODES,
  SET_SERVICE_AREAS,
  ADD_SERVICE_AREA,
  UPDATE_SERVICE_AREA,
  DELETE_SERVICE_AREA,
  APPEND_SERVICE_AREAS,
  ZipCode,
  ServiceArea,
} from './types';

export const initialState: ServiceAreaState = {
  zipCodes: [],
  serviceAreas: [],
};

export default function reducer(
  state: ServiceAreaState = initialState,
  action: ServiceAreaActionTypes,
): ServiceAreaState {
  switch (action.type) {
    case SET_ZIP_CODES:
      return {
        ...state,
        zipCodes: action.zipCodes,
      };
    case APPEND_SERVICE_AREAS:
      return {
        ...state,
        serviceAreas: [...state.serviceAreas, ...action.serviceAreas],
      };
    case SET_SERVICE_AREAS:
      return {
        ...state,
        serviceAreas: action.serviceAreas,
      };
    case ADD_SERVICE_AREA:
      return {
        ...state,
        serviceAreas: [...state.serviceAreas, action.serviceArea],
      };
    case UPDATE_SERVICE_AREA:
      return {
        ...state,
        serviceAreas: state.serviceAreas.map(
          (vsa) => (vsa.id === action.serviceArea.id ? action.serviceArea : vsa),
        ),
      };
    case DELETE_SERVICE_AREA:
      return {
        ...state,
        serviceAreas: state.serviceAreas.filter(
          (vsa) => !(vsa.id === action.serviceArea.id),
        ),
      };
    default:
      return state;
  }
}

export const setZipCodes = (
  dispatch: Dispatch<SetZipCodes>,
) => (zipCodes: ZipCode[]) => dispatch({
  type: SET_ZIP_CODES,
  zipCodes,
});

export const setServiceAreas = (
  dispatch: Dispatch<SetServiceAreas>,
) => (serviceAreas: ServiceArea[]) => dispatch({
  type: SET_SERVICE_AREAS,
  serviceAreas,
});

export const addServiceArea = (
  dispatch: Dispatch<AddServiceArea>,
) => (serviceArea: ServiceArea) => dispatch({
  type: ADD_SERVICE_AREA,
  serviceArea,
});

export const updateServiceArea = (
  dispatch: Dispatch<UpdateServiceArea>,
) => (serviceArea: ServiceArea) => dispatch({
  type: UPDATE_SERVICE_AREA,
  serviceArea,
});

export const deleteServiceArea = (
  dispatch: Dispatch<DeleteServiceArea>,
) => (serviceArea: ServiceArea) => dispatch({
  type: DELETE_SERVICE_AREA,
  serviceArea,
});

export const appendServiceAreas = (
  dispatch: Dispatch<AppendServiceAreas>,
) => (serviceAreas: ServiceArea[]) => dispatch({
  type: APPEND_SERVICE_AREAS,
  serviceAreas,
});
