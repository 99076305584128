import { useEffect, FunctionComponent } from 'react';
import { Form } from 'react-final-form';
import Modal from '@app/components/modal';
import { useServiceAreaContext } from '@app/contexts/service-area-context';
import Button from '@app/components/button';
import FormFieldInput from '@app/forms/fields/input';
import { RawServiceArea } from '@app/contexts/service-area-context/types';
import { useUserContext } from '@app/contexts/user-context';

type Prop = {
  isOpen: boolean;
  onClose: () => void;
};

const VendorServiceAreaEditModal: FunctionComponent<Prop> = ({
  isOpen,
  onClose,
}) => {
  const { currentUser } = useUserContext();
  const {
    selectedServiceArea,
    updateServiceArea,
    setSelectedServiceArea,
  } = useServiceAreaContext();

  useEffect(() => {
    if (isOpen && selectedServiceArea != null) return;
    onClose();
  }, [isOpen]);

  const handleOnSubmit = (payload: RawServiceArea) => {
    if (currentUser?.vendor?.id == null) return;
    const { zipCodeId, radius } = payload;

    updateServiceArea({
      vendorId: currentUser?.vendor?.id, zipCodeId, radius: +radius, type: 'address',
    });
    setSelectedServiceArea({
      ...selectedServiceArea, radius: +radius,
    });
    onClose();
  };

  return (
    <Modal isOpen={isOpen && selectedServiceArea.type === 'address'} onRequestClose={onClose}>
      <Form
        onSubmit={handleOnSubmit}
        initialValues={{
          zipCodeId: selectedServiceArea?.zipCode?.id,
          radius: selectedServiceArea?.radius,
        }}
        validate={({ radius }) => (radius > 0 ? {} : { radius: 'Invalid value for radius' })}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <h6>
                <strong>Update Service Area</strong>
              </h6>
              <p className="p-2">
                {selectedServiceArea?.address1}
              </p>
            </div>
            <div className="mb-10 flex flex-col gap-y-2">
              <FormFieldInput
                blockClassName="w-full"
                name="radius"
                label="Radius (miles)"
                type="number"
                min={1}
                required
              />
            </div>
            <div className="flex space-x-3">
              <Button full type="submit" disabled={submitting}>
                {submitting ? 'Saving' : 'Save'}
              </Button>
              <Button full buttonType="secondary" onClick={onClose}>
                Cancel
              </Button>
            </div>
          </form>
        )}
      />
    </Modal>
  );
};

export default VendorServiceAreaEditModal;
