import { Dispatch } from 'react';
import {
  VendorContainerState,
  VendorContainerActionTypes,
  SET_CONTAINERS,
  ADD_CONTAINER,
  UPDATE_CONTAINER,
  DELETE_CONTAINER,
  SetContainers,
  AddContainer,
  UpdateContainer,
  DeleteContainer,
  VendorContainer,
} from './types';

export const initialState: VendorContainerState = {
  containers: [],
};

export default function reducer(
  state: VendorContainerState = initialState,
  action: VendorContainerActionTypes,
): VendorContainerState {
  switch (action.type) {
    case SET_CONTAINERS:
      return {
        ...state,
        containers: action.containers,
      };
    case ADD_CONTAINER: {
      return {
        ...state,
        containers: [...state.containers, action.container],
      };
    }
    case UPDATE_CONTAINER: {
      return {
        ...state,
        containers: state.containers.map(
          (c) => (c.id === action.container.id ? action.container : c),
        ),
      };
    }
    case DELETE_CONTAINER: {
      return {
        ...state,
        containers: state.containers.filter((c) => c.id !== action.container.id),
      };
    }
    default:
      return state;
  }
}

export const setContainers = (
  dispatch: Dispatch<SetContainers>,
) => (containers: VendorContainer[]) => dispatch({
  type: SET_CONTAINERS,
  containers,
});

export const addContainer = (
  dispatch: Dispatch<AddContainer>,
) => (container: VendorContainer) => dispatch({
  type: ADD_CONTAINER,
  container,
});

export const updateContainer = (
  dispatch: Dispatch<UpdateContainer>,
) => (container: VendorContainer) => dispatch({
  type: UPDATE_CONTAINER,
  container,
});

export const deleteContainer = (
  dispatch: Dispatch<DeleteContainer>,
) => (container: VendorContainer) => dispatch({
  type: DELETE_CONTAINER,
  container,
});
