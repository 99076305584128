import type { AppProps as NextAppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import Head from 'next/head';
import ThemeProvider from '@app/contexts/theme-context';
import GlobalContextProvider from '@app/contexts/global-context-provider';
import { DEFAULT_META_TITLE, DEFAULT_META_DESCRIPTION } from '@app/constants/default';
import '@app/styles/globals.css';
import 'react-toastify/dist/ReactToastify.css';
import AuthGuard from '@app/components/auth-guard';
import ToastContainer from '@app/components/toast-container';
import { SWRConfig } from 'swr';
import { fetcher } from '@app/lib/api';
import TooltipContainer from '@app/components/tooltip-container';
import GooglePlaces from '@app/providers/google-places';
import NextNProgress from 'nextjs-progressbar';
import { ReactLiveChatAuthenticated, ReactLiveChat } from '@app/providers/livechat';
import { Fragment } from 'react';
import dynamic from 'next/dynamic';

export const DyanicIsOffline = dynamic(
  () => import('@app/components/is-offline'),
  { ssr: false },
);

export interface AppProps extends NextAppProps {
  Component: NextAppProps['Component'] & { requireAuth: boolean }
}

// Use the <Provider> to improve performance and allow components that call
// `useSession()` anywhere in your application to access the `session` object.
const App = ({ Component, pageProps }: AppProps) => (
  <SessionProvider
    session={pageProps.session}
    // Re-fetch session every 5 minutes
    refetchInterval={5 * 60}
  >
    <SWRConfig
      value={{
        refreshInterval: 60000,
        fetcher,
      }}
    >
      <ThemeProvider>
        <Head>
          <title>{DEFAULT_META_TITLE}</title>
          <meta name="description" content={DEFAULT_META_DESCRIPTION} />
          <meta
            name="viewport"
            // eslint-disable-next-line max-len
            content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=5,viewport-fit=cover"
          />
        </Head>
        <GooglePlaces />
        <NextNProgress options={{ showSpinner: false }} />
        {Component.requireAuth ? (
          <GlobalContextProvider>
            <AuthGuard>
              <Component {...pageProps} />
              <ReactLiveChatAuthenticated />
            </AuthGuard>
          </GlobalContextProvider>

        ) : (
          <Fragment>
            <Component {...pageProps} />
            <ReactLiveChat />
          </Fragment>
        )}
        <ToastContainer />
        <TooltipContainer />
        <DyanicIsOffline />
      </ThemeProvider>
    </SWRConfig>
  </SessionProvider>
);

export default App;
