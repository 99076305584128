import {
  createContext, FunctionComponent, useContext, useState,
} from 'react';
import { Context } from './types';

const AppContext = createContext<Context|any>({});

const AppProvider: FunctionComponent = ({ children }) => {
  const initialLoadingProcesses = ['fetchWasteStreams', 'fetchContainers', 'listServiceAreas', 'getCurrentUser'];
  const [loading, setLoading] = useState<string[]>(initialLoadingProcesses);
  const [isOpenOnboardingModal, setIsOpenOnboardingModal] = useState<boolean>(false);

  const isLoading = (key: string) => loading.some((l) => l === key);

  const addLoading = (key: string) => setLoading((prev) => [...prev, key]);

  const removeLoading = (key: string) => setLoading((prev) => prev.filter((k) => k !== key));
  const value: Context = {
    isLoading,
    addLoading,
    removeLoading,
    isOpenOnboardingModal,
    openOnboardingModal: () => setIsOpenOnboardingModal(true),
    closeOnboardingModal: () => setIsOpenOnboardingModal(false),
  };

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => useContext<Context>(AppContext);

export { AppProvider, useAppContext };
