import { Vendor } from '@app/typings/vendor-types';

export type OnboardingKeyType =
  | 'GETTING_STARTED'
  | 'SERVICE_OFFERING_INTRO'
  | 'FEATURE_SERVICE_AREA'
  | 'FEATURE_CONTAINER'
  | 'FEATURE_WASTESTREAM'
  | 'ALL_SETUP'
  | 'APPROVED'

export type OnboardingKeyItem = {
  key: OnboardingKeyType;
  order: number;
};

export type UserStatus = 'verified' | 'pending' | 'deleted' | 'changed';
export type VendorStatus = 'approved' | 'pending' | 'rejected';

export type Statuses = {
  vendorStatus: VendorStatus;
  userStatus: UserStatus;
}

export type UserPermissionsFormatted = {
  canEditVendorProfile: boolean;
  canEditVendorWasteStream: boolean;
  canDeleteVendorWasteStream: boolean;
  canEditVendorContainer: boolean;
  canDeleteVendorContainer: boolean;
  canEditServiceArea: boolean;
  canDeleteServiceArea: boolean;
}

export type User = {
  createdAt: Date;
  createdBy: number;
  email: string;
  firstName: string;
  fullName: string;
  hasSocial: boolean;
  hasMicrosoft: boolean;
  hasFb: boolean;
  id: number;
  lastName: string;
  primaryPhone: string;
  website: string;
  roles: string[];
  updatedBy: number | null;
  updatedAt: Date | null;
  vendor?: Vendor;
  onboardingSteps: OnboardingKeyType[];
  statuses: Statuses
  allowed: UserPermissionsFormatted
};

export type UserState = {
  currentUser: User | null;
  onboardingSteps: OnboardingKeyType[];
};

export type UserContextType = UserState & {
  setCurrentUser: (params: User | null) => void;
  addOnboardingStep: (s: OnboardingKeyType) => Promise<void>;
  removeOnboardingStep: (s: OnboardingKeyType) => Promise<void>;
  isApproved: boolean;
  isNotInStepper: boolean;
};

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_ONBOARDING_STEPS = 'SET_ONBOARDING_STEPS';
export const ADD_ONBOARDING_STEP = 'ADD_ONBOARDING_STEP';
export const REMOVE_ONBOARDING_STEP = 'REMOVE_ONBOARDING_STEP';

export type SetCurrentUser = {
  type: typeof SET_CURRENT_USER;
  user: User | null;
};

export type SetOnboardingSteps = {
  type: typeof SET_ONBOARDING_STEPS;
  steps: OnboardingKeyType[];
};

export type AddOnboardingStep = {
  type: typeof ADD_ONBOARDING_STEP;
  step: OnboardingKeyType;
};

export type RemoveOnboardingStep = {
  type: typeof REMOVE_ONBOARDING_STEP;
  step: OnboardingKeyType;
};

export type UserActionTypes =
  | SetCurrentUser
  | AddOnboardingStep
  | RemoveOnboardingStep
  | SetOnboardingSteps;
