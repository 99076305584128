const colors = require('tailwindcss/colors');
const tailwindForms = require('@tailwindcss/forms');
const plugin = require('tailwindcss/plugin');
const defaultTheme = require('tailwindcss/defaultTheme');

const assignColor = (light, DEFAULT, dark, lowEm) => ({
  light: light || DEFAULT,
  DEFAULT,
  dark,
  'low-em': lowEm || null,
});

const assignStyle = (fontSize, fontWeight, lineHeight, letterSpacing) => ({
  fontSize,
  fontWeight,
  lineHeight,
  letterSpacing,
});

module.exports = {
  mode: 'jit',
  purge: {
    content: [
      './pages/**/*.{js,ts,jsx,tsx}',
      './components/**/*.{js,ts,jsx,tsx}',
      './forms/**/*.{js,ts,jsx,tsx}',
    ],
    options: {
      keyframes: true,
    },
  },
  darkMode: 'class', // or 'media' or 'class'
  theme: {
    extend: {},
    screens: {
      xs: '375px',
      ...defaultTheme.screens,
    },
    colors: {
      black: colors.black,
      indigo: colors.indigo,
      orange: colors.orange,
      white: colors.white,
      gray: colors.coolGray,
      red: colors.red,
      green: colors.green,
      blue: colors.blue,
      transparent: 'transparent',
      current: 'currentColor',
      info: '#11324D',
      light: '#FFFFFF',
      primary: assignColor('#06B6D4', '#0891B2', '#0E7490', '#ecfeff'),
      secondary: assignColor('#22C55E', '#16A34A', '#158040', '#f0fdf4'),
      base: assignColor('#f3f6f9', '#fff', '#0C4A6E'),
      success: assignColor('#F7FEE7', '#A3E635', '#65A30D'),
      warning: assignColor('#FEF9C3', '#FDE047', '#EAB308'),
      error: assignColor('#FEE2E2', '#EF4444', '#B91C1C'),
      'text-color': {
        'high-em': assignColor(null, '#334155', '#FFFFFF'),
        'mid-em': assignColor(null, '#64748b', '#FFFFFF'),
        'low-em': assignColor(null, '#94a3b8', '#FFFFFF'),
        disabled: assignColor(null, '#cbd5e1', '#FFFFFF'),
        light: '#FFFFFF',
      },
      background: {
        'primary-dark': '#0C4A6E',
        main: assignColor(null, '#FFFFFF', '#1f2937'),
        'mid-em': assignColor(null, '#f3f4f6', '#111827'),
        'low-em': assignColor(null, '#f9fafb', '#374151'),
        stroke: assignColor(null, '#e5e7eb', '#4b5563'),
        disabled: assignColor(null, '#f3f4f6', '#111827'),
      },
    },
  },
  variants: {
    extend: {
      backgroundColor: ['odd', 'even'],
      opacity: ['disabled'],
      cursor: ['disabled'],
      divideColor: ['dark'],
    },
  },
  plugins: [
    tailwindForms,
    plugin(({ addUtilities, addBase }) => {
      addUtilities({
        '.text-body-lg': assignStyle('16px', 'normal', '24px', '0px'),
        '.text-body-md': assignStyle('14px', 'normal', '20px', '0px'),
        '.text-body-sm': assignStyle('12px', 'normal', '16px', '0.25px'),
        '.label-lg': assignStyle('16px', '600', '24px', '0px'),
        '.label-md': assignStyle('14px', '500', '20px', '0px'),
        '.label-sm': assignStyle('12px', '500', '16px', '0px'),
        '.label-sm-bold': assignStyle('12px', '700', '16px', '0px'),
        '.btn-text-lg': assignStyle('16px', '500', '24px', '0px'),
        '.btn-text-base': assignStyle('14px', '500', '20px', '0px'),
        '.btn-text-sm': assignStyle('14px', '500', '16px', '0px'),
        '.btn-text-xs': assignStyle('12px', '500', '16px', '0px'),
        '.caption': assignStyle('11px', '500', '20px', '0.4px'),
        '.h1': assignStyle('40px', '700', '54px', '0px'),
        '.h2': assignStyle('30px', '700', '40px', '0px'),
        '.h3': assignStyle('24px', '700', '32px', '0px'),
        '.h4': assignStyle('18px', '700', '30px', '0px'),
        '.h5': assignStyle('18px', '500', '28px', '0px'),
        '.h6': assignStyle('18px', '400', '28px', '0px'),
        '.no-scrollbar': { '-ms-overflow-style': 'none', 'scroll-bar-width': 'none' },
      });
      addBase({
        h1: assignStyle('40px', '700', '54px', '0px'),
        h2: assignStyle('30px', '700', '40px', '0px'),
        h3: assignStyle('24px', '700', '32px', '0px'),
        h4: assignStyle('18px', '700', '30px', '0px'),
        h5: assignStyle('18px', '500', '28px', '0px'),
        h6: assignStyle('18px', '400', '28px', '0px'),
      });
    }),
  ],
};
