import {
  useState, useEffect, createContext, useContext, useReducer,
} from 'react';
import { useSession } from 'next-auth/react';
import { toast } from 'react-toastify';
import { frontendApi } from '@app/lib/api';
import { keysToCamelCase, convertDataStringToDate, keysToSnakeCase } from '@app/lib/helpers';
import {
  EditVendorContainerData,
  AddVendorContainerData,
  ContainerPanelProps,
  Context,
  VendorContainer,
  ContainerSize,
} from './types';
import reducer, {
  addContainer, deleteContainer, initialState, setContainers, updateContainer,
} from './container';
import { useAppContext } from '../app-context';
import { useUserContext } from '../user-context';

export const ContainerPanelContext = createContext<Context|any>({});

export const ContainerPanelProvider = ({ children }: ContainerPanelProps) => {
  const { status } = useSession();
  const { isApproved, isNotInStepper } = useUserContext();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [containerFormData, setContainerFormData] = useState<VendorContainer | null>(null);
  const { addLoading, removeLoading } = useAppContext();

  const formatContainerData = (d: any) => convertDataStringToDate(
    keysToCamelCase(
      {
        ...d,
        container: convertDataStringToDate(keysToCamelCase({
          ...d.container,
          containerSizes: d.container.container_sizes.map((cs) => convertDataStringToDate(keysToCamelCase(cs))),
          containerType: convertDataStringToDate(keysToCamelCase(d.container.container_type)),
        })),
      },
    ),
  );

  const fetchContainers = async () => {
    try {
      addLoading('fetchContainers');
      const response = await frontendApi.get('/bidding/vendor-container/me');
      removeLoading('fetchContainers');
      if (response.data == null) return;
      const formattedData: VendorContainer[] = response.data.map(formatContainerData);
      setContainers(dispatch)(formattedData);
    } catch (error) {
      console.error(error);
      removeLoading('fetchContainers');
    }
  };

  const fetchNoSizeContainerSize = async () => {
    try {
      addLoading('fetchNoSizeContainerSize');
      const response = await frontendApi.get('/options/no-size-container-size');
      if (response.data?.noSizeContainerSize == null) return null;
      return keysToCamelCase(
        {
          ...response.data.noSizeContainerSize,
          containerTypes: response.data.noSizeContainerSize.container_types.map(keysToCamelCase),
        },
      ) as ContainerSize;
    } catch (error) {
      console.error(error);
      removeLoading('fetchNoSizeContainerSize');
      return null;
    }
  };

  const handleAddContainer = async (payload: AddVendorContainerData) => {
    try {
      const response = await frontendApi.post('/bidding/vendor-container/me', keysToSnakeCase(payload));
      if (response.data.message == null || response.data.container == null) return;
      const newContainer = formatContainerData(response.data.container);
      addContainer(dispatch)(newContainer);
      toast.success(response.data.message);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteContainer = async (container: VendorContainer) => {
    try {
      const response = await frontendApi.delete(`/bidding/vendor-container/me/${container.id}`);
      if (response.data.message == null) return;
      deleteContainer(dispatch)(container);
      toast.success(response.data.message);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditContainer = async (payload: EditVendorContainerData) => {
    try {
      const response = await frontendApi.put(
        `/bidding/vendor-container/me/${containerFormData.id}`,
        keysToSnakeCase({ ...payload }),
      );

      if (response.data.message == null || response.data.container == null) return;
      const updatedContainer = formatContainerData(response.data.container);
      updateContainer(dispatch)(updatedContainer);
      toast.success(response.data.message);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (status !== 'authenticated') return;
    if (!isApproved) return;
    if (!isNotInStepper) return;
    fetchContainers();
  }, [status, isApproved, isNotInStepper]);

  return (
    <ContainerPanelContext.Provider value={{
      ...state,
      containerFormData,
      setContainerFormData,
      handleDeleteContainer,
      handleAddContainer,
      handleEditContainer,
      fetchNoSizeContainerSize,
    }}
    >
      {children}
    </ContainerPanelContext.Provider>
  );
};

const useContainerPanelContext = () => useContext<Context>(ContainerPanelContext);

export {
  useContainerPanelContext,
};
