import {
  createContext,
  FunctionComponent,
  useContext,
  useReducer,
  useState,
  useEffect,
} from 'react';
import { useSession } from 'next-auth/react';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import { frontendApi } from '@app/lib/api';
import { convertDataStringToDate, keysToCamelCase, keysToSnakeCase } from '@app/lib/helpers';
import {
  PROFILE_PAGE, OPEN_BIDS_PAGE, TRACK_SUBMITTED_BIDS_PAGE, HOME_PAGE,
} from '@app/constants/routes';
import { useUserContext } from '@app/contexts/user-context';
import reducer, {
  initialState,
  setZipCodes,
  setServiceAreas,
  appendServiceAreas,
  addServiceArea,
  updateServiceArea,
  deleteServiceArea,
} from './service-area';
import { useAppContext } from '../app-context';
import {
  Context,
  ZipCode,
  ServiceArea,
  RawServiceArea,
} from './types';

const ServiceAreaContext = createContext<Context|any>({});

const ServiceAreaProvider: FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { status } = useSession();
  const [selectedServiceArea, setSelectedServiceArea] = useState<ServiceArea | null>(null);
  const router = useRouter();
  const isAtVendorProfile = router.pathname === PROFILE_PAGE && router.query.tab === 'vendorProfile';
  const isAtOpenBidsPage = router.pathname === OPEN_BIDS_PAGE;
  const isAtTrackSubmittedBidsPage = router.pathname === TRACK_SUBMITTED_BIDS_PAGE;
  const isAtDashboard = router.pathname === HOME_PAGE;
  const { addLoading, removeLoading } = useAppContext();
  const { currentUser, isApproved, isNotInStepper } = useUserContext();

  const listZipCodes = async (search?: string, vendorId?: number) => {
    const searchParams = search == null ? {} : { search };
    const vendorIdParams = vendorId == null ? {} : { vendorId };
    const response = await frontendApi.get('/zip-code', {
      params: keysToSnakeCase({ ...vendorIdParams, ...searchParams }),
    });
    if (response.data == null) return [];
    const serviceAreas: ZipCode[] = response.data.map(keysToCamelCase).map(convertDataStringToDate);
    setZipCodes(dispatch)(serviceAreas);
    return serviceAreas;
  };

  const listServiceAreas = async ({
    vendorId,
    page = 1,
    take = 10,
    append = false,
  }: {
    vendorId: number;
    page?: number;
    take?: number;
    append?: boolean;
  }) => {
    addLoading('listServiceAreas');
    const response = await frontendApi.get(
      `/vendor/${vendorId}/service_area`,
      { params: { page, take } },
    );
    removeLoading('listServiceAreas');
    if (response.data == null) return [];
    const serviceAreas: ServiceArea[] = response.data
      .map((data) => keysToCamelCase({
        ...data,
        serviceArea: convertDataStringToDate(
          keysToCamelCase(data.service_area),
        ),
      }))
      .map(convertDataStringToDate);
    if (append) {
      appendServiceAreas(dispatch)(serviceAreas);
    } else {
      setServiceAreas(dispatch)(serviceAreas);
    }
    return serviceAreas;
  };

  // fetch vendor service area if at needed page
  useEffect(() => {
    if (status !== 'authenticated') return;
    if (
      !(
        isAtVendorProfile
        || isAtOpenBidsPage
        || isAtTrackSubmittedBidsPage
        || isAtDashboard
      )
      || currentUser?.vendor == null
    ) { return; }
    if (!isApproved) return;
    if (!isNotInStepper) return;
    listServiceAreas({ vendorId: currentUser.vendor.id });
  }, [
    isAtVendorProfile,
    isAtOpenBidsPage,
    currentUser?.vendor,
    isAtTrackSubmittedBidsPage,
    isAtDashboard,
    status,
    isApproved,
    isNotInStepper,
  ]);

  const addServiceAreaToVendor = async (body: RawServiceArea) => {
    const response = await frontendApi.post(`/vendor/${body.vendorId}/service_area`, keysToSnakeCase(body));
    if (response.data == null || response.data.message == null || response.data.serviceArea == null) return;
    toast.success(response.data.message);
    const formattedResult: ServiceArea = convertDataStringToDate(
      keysToCamelCase({
        ...response.data.serviceArea,
        zipCode: convertDataStringToDate(keysToCamelCase(response.data.serviceArea.zip_code)),
      }),
    );
    addServiceArea(dispatch)(formattedResult);
  };

  const handleUpdateServiceArea = async ({
    vendorId, zipCodeId, radius, type,
  }: RawServiceArea) => {
    const response = await frontendApi.put(
      `/vendor/${vendorId}/service_area/${zipCodeId}`,
      { radius, type },
    );
    if (response.data == null || response.data.message == null || response.data.serviceArea == null) return;
    toast.success(response.data.message);
    const formattedResult: ServiceArea = convertDataStringToDate(
      keysToCamelCase({
        ...response.data.serviceArea,
        zipCode: convertDataStringToDate(keysToCamelCase(response.data.serviceArea.zip_code)),
        radius,
      }),
    );
    updateServiceArea(dispatch)(formattedResult);
  };

  const handleDeleteorServiceArea = async (
    serviceArea: ServiceArea,
  ) => {
    if (currentUser?.vendor?.id == null) return;
    const response = await frontendApi.delete(
      `/vendor/${currentUser.vendor.id}/service_area/${serviceArea.zipCode.id}`,
    );
    if (response.data == null) return;
    toast.success(response.data.message);
    deleteServiceArea(dispatch)(serviceArea);
  };

  const value: Context = {
    ...state,
    selectedServiceArea,
    listZipCodes,
    listServiceAreas,
    addServiceAreaToVendor,
    updateServiceArea: handleUpdateServiceArea,
    deleteServiceArea: handleDeleteorServiceArea,
    setSelectedServiceArea: (v: ServiceArea) => setSelectedServiceArea(v),
  };

  return (
    <ServiceAreaContext.Provider value={value}>
      {children}
    </ServiceAreaContext.Provider>
  );
};

const useServiceAreaContext = () => useContext<Context>(ServiceAreaContext);

export { ServiceAreaProvider, useServiceAreaContext };
