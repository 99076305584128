import {
  useState, createContext, ReactNode, useContext,
} from 'react';

export const VendorPanelModalContext = createContext(null);

export interface VendorPanelModalProps {
  children: ReactNode;
}

export const VendorPanelModalProvider = ({
  children,
}: VendorPanelModalProps) => {
  const [isOpenServiceAreaTable, setIsOpenServiceAreaTable] = useState<boolean>(false);
  const [isOpenWasteStreamTable, setIsOpenWasteStreamTable] = useState<boolean>(false);
  const [isOpenContainerTable, setIsOpenContainerTable] = useState<boolean>(false);
  const [isServiceAreaTableAddMode, setIsServiceAreaTableAddMode] = useState<boolean>(false);
  const [isWasteStreamTableAddMode, setIsWasteStreamTableAddMode] = useState<boolean>(false);
  const [isContainerTableAddMode, setIsContainerTableAddMode] = useState<boolean>(false);
  const [isEditContainerModalOpen, setIsEditContainerModalOpen] = useState<boolean>(false);
  return (
    <VendorPanelModalContext.Provider
      value={{
        isOpenServiceAreaTable,
        openServiceAreaTable: () => setIsOpenServiceAreaTable(true),
        closeServiceAreaTable: () => setIsOpenServiceAreaTable(false),
        isOpenWasteStreamTable,
        openWasteStreamTable: () => setIsOpenWasteStreamTable(true),
        closeWasteStreamTable: () => setIsOpenWasteStreamTable(false),
        isOpenContainerTable,
        openContainerTable: () => setIsOpenContainerTable(true),
        closeContainerTable: () => setIsOpenContainerTable(false),
        isServiceAreaTableAddMode,
        setIsServiceAreaTableAddMode,
        isWasteStreamTableAddMode,
        setIsWasteStreamTableAddMode,
        isContainerTableAddMode,
        setIsContainerTableAddMode,
        isEditContainerModalOpen,
        closeEditContainerModal: () => setIsEditContainerModalOpen(false),
        openEditContainerModal: () => setIsEditContainerModalOpen(true),
      }}
    >
      {children}
    </VendorPanelModalContext.Provider>
  );
};

export default VendorPanelModalProvider;

const useVendorPanelModalContext = () => useContext(VendorPanelModalContext);

export { useVendorPanelModalContext };
