import { ThemeContext } from '@app/contexts/theme-context';
import { useContext } from 'react';
import { mdiWeatherSunny, mdiWeatherNight } from '@mdi/js';
import Icon from '@mdi/react';
import IconButton from './icon-button';

const NavbarTheme = () => {
  const { theme, handleThemeChange } = useContext(ThemeContext);
  const isDark = theme === 'dark';

  return (
    <IconButton
      aria-label="Theme Toggle"
      data-tip={`Set to ${isDark ? 'light' : 'dark'} theme`}
      onClick={() => handleThemeChange(isDark ? 'light' : 'dark')}
      icon={isDark
        ? (
          <Icon
            size={1.2}
            path={mdiWeatherSunny}
          />
        )
        : (
          <Icon
            size={1.2}
            path={mdiWeatherNight}
          />
        )}
    />
  );
};

export const NavbarThemeText = () => {
  const { theme, handleThemeChange } = useContext(ThemeContext);
  const isDark = theme === 'dark';

  return (
    <div className="mt-1 mr-1 btn-text-xs flex items-center justify-end text-text-color-mid-em">
      <NavbarTheme />
      <button aria-label="Theme Toggle" type="button" onClick={() => handleThemeChange(isDark ? 'light' : 'dark')}>
        <span>
          Toggle Theme
        </span>
      </button>
    </div>
  );
};

export default NavbarTheme;
