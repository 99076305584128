import Image from 'next/image';
import { useThemeContext } from '@app/contexts/theme-context';

const Intro = () => {
  const { theme, isMobile } = useThemeContext();
  const isDarkMode = theme === 'dark';
  const imagePath = `/images/onboarding/service-offering-intro${
    isDarkMode ? '-dark' : ''
  }.png`;
  return (
    <div className="grid grid-cols-12 pt-8 pb-5 md:pt-20 md:pb-10 text-left">
      {!isMobile && (
      <div className="col-span-12 md:col-span-6 shadow-xl">
        <Image src={imagePath} width={490} height={404} />
      </div>
      )}
      <div className="col-span-12 md:col-span-6 flex flex-col gap-y-6 px-10">
        <div>
          <Image
            src="/images/onboarding/modal-logo.svg"
            width={56}
            height={56}
          />
        </div>
        <span className="h3 text-background-primary-dark dark:text-base-light">
          Define your Service Offering
        </span>
        <div className="p-2 text-body-lg">
          <div className="flex flex-col gap-y-5">
            <div className="grid grid-cols-12">
              <div className="col-span-2 p-3">
                <Image
                  src="/images/onboarding/check.svg"
                  width={28}
                  height={28}
                />
              </div>
              <span className="col-span-10">
                Only get notified of bids that match your service offering
              </span>
            </div>
            <div className="grid grid-cols-12">
              <div className="col-span-2 p-3">
                <Image
                  src="/images/onboarding/check.svg"
                  width={28}
                  height={28}
                />
              </div>
              <span className="col-span-10">
                {`Define what Waste Streams, Service Areas, Container Type & Sizes 
              you offer to receive personalized notifications about your new bids.`}
              </span>
            </div>
          </div>
        </div>
        <span>Now, let set up your own service offerings.</span>
      </div>
    </div>
  );
};

export default Intro;
