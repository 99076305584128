/* global google */
import { useThemeContext } from '@app/contexts/theme-context';
import PlacesAutocomplete from 'react-places-autocomplete';
import FormFieldInput from '@app/forms/fields/input';
import { extractedTailwindTheme } from '@app/lib/helpers';

type Prop = {
  value: string;
  onChange: (address: string) => void;
  onSelect: (address: string) => void;
};
const AddressSearch = ({ value, onChange, onSelect }: Prop) => {
  const { theme } = useThemeContext();
  const isDark = theme === 'dark';
  return (
    <PlacesAutocomplete
      value={value}
      onChange={onChange}
      onSelect={onSelect}
      searchOptions={{
        location: new google.maps.LatLng(39, -95),
        radius: 1000,
        types: ['address'],
        componentRestrictions: { country: ['us'] },
      }}
      onError={(status, clearSuggestions) => {
        clearSuggestions();
      }}
    >
      {({
        getInputProps, suggestions, getSuggestionItemProps, loading,
      }) => (
        <div className="relative">
          <FormFieldInput
            name="address"
            label=""
            {...getInputProps({
              placeholder: 'Search for address',
            })}
          />
          <div className="absolute top-10 w-full shadow-xl rounded-md overflow-hidden">
            {loading && <div className="p-2">Loading...</div>}
            {suggestions.map((suggestion, i) => {
              const style = suggestion.active
                ? {
                  backgroundColor: isDark ? extractedTailwindTheme.colors.gray['500']
                    : extractedTailwindTheme.colors.gray['200'],
                  cursor: 'pointer',
                }
                : {
                  backgroundColor: isDark
                    ? extractedTailwindTheme.colors.background.main.dark
                    : extractedTailwindTheme.colors.gray['100'],
                  cursor: 'pointer',
                };
              return (
                <div
                  key={i}
                  {...getSuggestionItemProps(suggestion, {
                    style,
                  })}
                  className="p-2 w-full text-left"
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default AddressSearch;
