import { Dispatch, ReactNode, SetStateAction } from 'react';

export interface ContainerPanelProps {
  children: ReactNode
}

export type ContainerType = {
  createdAt: Date;
  createdBy: number;
  id: number;
  name: string;
  updateAt: Date;
  updatedBy: number;
}

export type ContainerSize = {
  createdAt: Date;
  createdBy: number;
  id: number;
  name: string;
  updateAt: Date;
  updatedBy: number;
  containerTypes?: ContainerType[]
}

export type Container = {
  createdAt: Date;
  createdBy: number;
  id: number;
  updateAt: Date;
  updatedBy: number;
  containerSizes: ContainerSize[];
  containerType: ContainerType;
}

export type AddVendorContainerData = {
  vendorId: number,
  containerTypeId: number,
  containerSizeIds: number[],
}

export type EditVendorContainerData = AddVendorContainerData & {
  containerId: number
};

export type VendorContainer = {
  createdAt: Date;
  createdBy: number;
  id: number;
  updatedAt: Date;
  container: Container;
}

export type VendorContainerState = {
  containers: VendorContainer[]
}

export type Context = VendorContainerState & {
  handleDeleteContainer: (p: VendorContainer) => Promise<void>;
  handleAddContainer: (p: AddVendorContainerData) => Promise<void>;
  handleEditContainer: (p: EditVendorContainerData) => Promise<void>;
  fetchNoSizeContainerSize: () => Promise<ContainerSize | null>;
  setContainerFormData: Dispatch<SetStateAction<VendorContainer>>
  containerFormData: VendorContainer;
  containerFormDate: VendorContainer;
};

export const SET_CONTAINERS = 'SET_CONTAINERS';
export const ADD_CONTAINER = 'ADD_CONTAINER';
export const UPDATE_CONTAINER = 'UPDATE_CONTAINER';
export const DELETE_CONTAINER = 'DELETE_CONTAINER';

export type SetContainers = {
  type: typeof SET_CONTAINERS,
  containers: VendorContainer[]
};

export type AddContainer = {
  type: typeof ADD_CONTAINER,
  container: VendorContainer,
};

export type UpdateContainer = {
  type: typeof UPDATE_CONTAINER,
  container: VendorContainer,
};

export type DeleteContainer = {
  type: typeof DELETE_CONTAINER,
  container: VendorContainer,
};

export type VendorContainerActionTypes =
  | AddContainer
  | UpdateContainer
  | DeleteContainer
  | SetContainers
