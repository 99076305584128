import { ThemeContext } from '@app/contexts/theme-context';
import { useContext } from 'react';
import { ToastContainer as ReactToastContainer } from 'react-toastify';

const ToastContainer = () => {
  const { theme } = useContext(ThemeContext);
  const isDark = theme === 'dark';

  return <ReactToastContainer limit={3} position="top-right" theme={isDark ? 'dark' : 'light'} />;
};

export default ToastContainer;
