import { frontendApi } from '@app/lib/api';
import { convertDataStringToDate, keysToCamelCase, keysToSnakeCase } from '@app/lib/helpers';
import { useSession } from 'next-auth/react';
import {
  useState, createContext, useEffect, useContext, useReducer,
} from 'react';
import { toast } from 'react-toastify';
import { useAppContext } from '../app-context';
import { useUserContext } from '../user-context';
import {
  Context, WasteStreamPanelContextProps, VendorWasteStream,
} from './types';
import reducer, {
  addVendorWasteStreams, deleteVendorWasteStream, initialState, setVendorWasteStreams,
} from './waste-stream';

const WasteStreamPanelContext = createContext<Context | any>({});

export const WasteStreamPanelContextProvider = ({ children }: WasteStreamPanelContextProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { status } = useSession();
  const { isApproved, isNotInStepper } = useUserContext();
  const [selectedVendorWasteStream, setSelectedVendorWasteStream] = useState<null | VendorWasteStream>(null);
  const { addLoading, removeLoading } = useAppContext();

  const fetchWasteStreams = async () => {
    try {
      addLoading('fetchWasteStreams');
      const response = await frontendApi.get('/auth/vendor-waste-stream/me');
      removeLoading('fetchWasteStreams');
      if (response.data == null) return [];
      const vendorWasteStreams: VendorWasteStream[] = response.data
        .map((data) => keysToCamelCase({
          ...data,
          wasteStream: keysToCamelCase(data.waste_stream),
        }))
        .map(convertDataStringToDate);
      setVendorWasteStreams(dispatch)(vendorWasteStreams);
      return vendorWasteStreams;
    } catch (error) {
      console.error(error);
      removeLoading('fetchWasteStreams');
      return [];
    }
  };

  const handleAddWasteStreams = async (wasteStreamIds: number[]) => {
    try {
      const response = await frontendApi.post('/auth/vendor-waste-stream/me',
        keysToSnakeCase({ wasteStreamIds }));
      if (response.data == null || response.data.message == null || response.data.wasteStreams == null) return;
      toast.success(response.data.message);
      const addedVendorWasteStreams: VendorWasteStream[] = response.data.wasteStreams
        .map((data) => keysToCamelCase({
          ...data,
          wasteStream: keysToCamelCase(data.waste_stream),
        }))
        .map(convertDataStringToDate);
      addVendorWasteStreams(dispatch)(addedVendorWasteStreams);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteWasteStream = async (vendorWasteStreamId: number) => {
    try {
      const response = await frontendApi.delete(`/auth/vendor-waste-stream/${vendorWasteStreamId}/me`);
      if (response.data == null) return;
      toast.success(response.data.message);
      deleteVendorWasteStream(dispatch)(vendorWasteStreamId);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (status !== 'authenticated') return;
    if (!isApproved) return;
    if (!isNotInStepper) return;
    fetchWasteStreams();
  }, [status, isApproved, isNotInStepper]);

  const value: Context = {
    ...state,
    setSelectedVendorWasteStream: (v) => setSelectedVendorWasteStream(v),
    selectedVendorWasteStream,
    handleAddWasteStreams,
    handleDeleteWasteStream,
  };

  return (
    <WasteStreamPanelContext.Provider
      value={value}
    >
      {children}
    </WasteStreamPanelContext.Provider>
  );
};

export default WasteStreamPanelContextProvider;

const useWasteStreamPanelContext = () => useContext<Context>(WasteStreamPanelContext);

export {
  useWasteStreamPanelContext,
};
