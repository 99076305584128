/**
 * This are all the available routes supported by the project. This
 * should match to the contents of "pages" directory.
 *
 * Refer to: https://nextjs.org/docs/basic-features/pages
 *
 * NOTE: Additional pages should be suffixed with "_PAGE"
 */
export const HOME_PAGE = '/';
export const LOGIN_PAGE = '/login';
export const SIGNUP_PROCESS_PAGE = '/signup-process';
export const FAQ_PAGE = '/faq';
export const OPEN_BIDS_PAGE = '/open-bids';
export const HELP_PAGE = '/help';
export const FORGOT_PASSWORD_PAGE = '/forgot-password';
export const CHANGE_PASSWORD_PAGE = '/change-password';
export const REGISTER_PAGE = '/register';
export const PROFILE_PAGE = '/profile';
export const TRACK_SUBMITTED_BIDS_PAGE = '/track-submitted-bids';
export const SETTINGS_PAGE = '/settings';
export const AUTH_ERROR_PAGE = '/auth-error';
export const LOGIN_WITH_TOKEN_PAGE = '/login-with-token';
export const OFFLINE_PAGE = '/_offline';
export const ERROR_PAGE = '/500';
export const MISSING_PAGE = '/404';
