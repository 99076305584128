import { Fragment } from 'react';
import { FieldMetaState } from 'react-final-form';

const MetaErrorHandler = ({ meta }: { meta: FieldMetaState<string> }) => {
  const isValid = (meta.error || meta.submitError) && meta.touched;

  const renderError = (
    <p className="text-red-600 mt-2 text-xs">
      {meta.submitError || meta.error}
    </p>
  );

  return isValid ? renderError : <Fragment />;
};

export default MetaErrorHandler;
