import { ReactNode, useContext } from 'react';
import { Field } from 'react-final-form';
import { isEmpty } from 'lodash';
import { LoadOptions, withAsyncPaginate } from 'react-select-async-paginate';
import { ThemeContext } from '@app/contexts/theme-context';
import { AxiosRequestConfig } from 'axios';
import { frontendApi } from '@app/lib/api';
import Creatable from 'react-select/creatable';
import ReactSelect, { OptionProps, Props } from 'react-select';
import { DEFAULT_LIMIT, Z_INDECES } from '@app/constants/default';
import { extractedTailwindTheme } from '@app/lib/helpers';
import MetaErrorHandler from './meta-error-handler';

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);
const ReactSelectAsyncPaginate = withAsyncPaginate(ReactSelect);

export type optionType = {
  label: string,
  value: string,
}

const CheckboxOption = (isDark) => (props: OptionProps<optionType, true>) => {
  const {
    getStyles, innerProps, innerRef, children, isSelected, theme, isFocused,
  } = props;
  return (
    <div
      style={{
        ...getStyles('option', props),
        // harcoded color from tailwind config since extracted version returns inaccurate
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: isFocused
          ? isDark
            ? extractedTailwindTheme.colors.primary.dark
            : '#06B6D4'
          : isDark
            ? '#1f2937'
            : extractedTailwindTheme.colors.white,
        color: isDark || isFocused
          ? extractedTailwindTheme.colors.white
          : theme.colors.black,
      }}
      ref={innerRef}
      {...innerProps}
    >
      <div className="flex flex-row items-center gap-x-2">
        <input
          className="rounded checked:bg-primary focus:ring-primary focus:checked:bg-primary"
          type="checkbox"
          checked={isSelected}
          readOnly
        />
        {children}
      </div>
    </div>
  );
};
export interface FormFieldReactSelectProps extends Omit<Props, 'isMulti'> {
  name: string,
  label: string,
  required?: boolean,
  apiUrl,
  isMulti?: boolean,
  params?: AxiosRequestConfig['params']
  onSelect?: (o: optionType) => void,
  disabled?: boolean,
  initialValue?: Object,
  canCreate?: boolean
  overrideLoadOptions?: LoadOptions<any, unknown>,
  selectKey?: number
  placeholder?: ReactNode
  showCheckBox?: boolean
}

// TODO: required
const FormFieldReactSelect = ({
  initialValue,
  name,
  apiUrl,
  label,
  required,
  onSelect,
  params,
  disabled = false,
  isMulti = false,
  overrideLoadOptions,
  canCreate = false,
  selectKey = 0,
  placeholder = '',
  showCheckBox = false,
  ...reactSelectProps
}: FormFieldReactSelectProps) => {
  const { theme } = useContext(ThemeContext);
  const isRequired = (value) => (value && !isEmpty(value) ? undefined : 'Required');

  async function loadOptions(search, _loadedOptions, { page }) {
    const { data: { results = [] } } = await frontendApi.get(`${apiUrl}?search=${search}&page=${page}`, {
      params,
    });

    // TODO: TMP ONLY
    const hasMore = results.length >= DEFAULT_LIMIT;

    return {
      options: results,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  const isDark = theme === 'dark';

  const customStyles = {
    valueContainer: (provided) => ({
      ...provided,
      display: 'flex',
      maxHeight: '36px',
      overflowY: 'auto',
    }),
    control: (provided) => ({
      ...provided,
      // eslint-disable-next-line no-nested-ternary
      backgroundColor: disabled ? isDark ? extractedTailwindTheme.colors.background.disabled.dark
        : extractedTailwindTheme.colors.background.disabled.DEFAULT
        : isDark ? '#1f2937'
          : extractedTailwindTheme.colors.white,
      borderColor: isDark ? extractedTailwindTheme.colors.gray['600'] : extractedTailwindTheme.colors.gray['300'],
    }),
    menu: (provided, _state) => ({
      ...provided,
      // harcoded color from tailwind config since .background attr returns undefined
      backgroundColor: isDark ? '#1f2937' : extractedTailwindTheme.colors.white,
      paddingBottom: 10,
    }),
    menuPortal: (p) => ({
      ...p,
      zIndex: Z_INDECES.DROPDOWN,
    }),
    menuList: (p) => ({
      ...p,
      backgroundColor: isDark ? '#1f2937' : extractedTailwindTheme.colors.white,
    }),
    input: (provided) => ({
      ...provided,
      backgroundColor: isDark ? '#1f2937'
        : extractedTailwindTheme.colors.white,
      color: isDark && extractedTailwindTheme.colors.white,
    }),
    singleValue: (provided, _state) => ({
      ...provided,
      color: isDark ? '#94A3B8' : '#64748B',
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      color: isFocused ? extractedTailwindTheme.colors.white : provided.color,
    }),
  };

  const SelectComponent = canCreate ? CreatableAsyncPaginate : ReactSelectAsyncPaginate;

  return (
    <Field name={name} validate={required && isRequired}>
      {({ input, meta }) => (
        <label className="text-gray-700 font-semibold" htmlFor={name}>
          <p className="mb-1">{label}</p>
          <SelectComponent
            menuPortalTarget={document.body}
            isMulti={isMulti}
            placeholder={<span className="text-text-color-mid-em dark:text-text-color-mid-em">{placeholder}</span>}
            classNamePrefix="react-select"
            styles={customStyles}
            isDisabled={disabled}
            key={`${JSON.stringify(params)}-${selectKey}`}
            value={input.value || initialValue}
            components={showCheckBox ? { Option: CheckboxOption(isDark) } : {}}
            onChange={(v) => {
              input.onChange(v);

              if (onSelect) {
                onSelect(v || {});
              }
            }}
            isClearable
            // eslint-disable-next-line react/jsx-no-bind
            loadOptions={overrideLoadOptions ?? loadOptions}
            additional={{
              page: 1,
            }}
            theme={(t) => ({
              ...t,
              colors: {
                ...t.colors,
                // harcoded color from tailwind config since extracted version returns inaccurate
                primary: isDark ? extractedTailwindTheme.colors.primary.dark : '#0891B2',
                primary25: isDark ? extractedTailwindTheme.colors.primary.dark : '#06B6D4',
              },
            })}
            {...reactSelectProps}
          />
          <MetaErrorHandler meta={meta} />
        </label>
      )}
    </Field>
  );
};
export default FormFieldReactSelect;
