import { Dispatch } from 'react';
import {
  AddVendorWasteStreams,
  ADD_VENDROR_WASTE_STREAMS,
  DeleteVendorWasteStream,
  DELETE_VENDOR_WASTE_STREAM,
  SetVendorWasteStreams,
  SET_VENDOR_WASTE_STREAMS,
  VendorWasteStream,
  VendorWasteStreamActionTypes,
  VendorWasteStreamState,
} from './types';

export const initialState: VendorWasteStreamState = {
  vendorWasteStreams: [],
};

export default function reducer(
  state: VendorWasteStreamState = initialState,
  action: VendorWasteStreamActionTypes,
): VendorWasteStreamState {
  switch (action.type) {
    case SET_VENDOR_WASTE_STREAMS:
      return {
        ...state,
        vendorWasteStreams: action.vendorWasteStreams,
      };

    case DELETE_VENDOR_WASTE_STREAM:
      return {
        ...state,
        vendorWasteStreams: state.vendorWasteStreams.filter((v) => v.id !== action.vendorWasteStreamId),
      };

    case ADD_VENDROR_WASTE_STREAMS:
      return {
        ...state,
        vendorWasteStreams: [...state.vendorWasteStreams, ...action.vendorWasteStreams],
      };

    default:
      return state;
  }
}

export const setVendorWasteStreams = (
  dispatch: Dispatch<SetVendorWasteStreams>,
) => (vendorWasteStreams: VendorWasteStream[]) => dispatch({
  type: SET_VENDOR_WASTE_STREAMS,
  vendorWasteStreams,
});

export const deleteVendorWasteStream = (
  dispatch: Dispatch<DeleteVendorWasteStream>,
) => (vendorWasteStreamId: number) => dispatch({
  type: DELETE_VENDOR_WASTE_STREAM,
  vendorWasteStreamId,
});

export const addVendorWasteStreams = (
  dispatch: Dispatch<AddVendorWasteStreams>,
) => (vendorWasteStreams: VendorWasteStream[]) => dispatch({
  type: ADD_VENDROR_WASTE_STREAMS,
  vendorWasteStreams,
});
