import {
  isObject, startCase, toLower, isArray, camelCase, mapKeys, snakeCase,
} from 'lodash';
import { User } from '@app/contexts/user-context/types';
import { FilterProps } from '@app/components/filter';
import { differenceInDays } from 'date-fns';
// TODO: SWBID-144
// import tailwindConfig from /* preval */ '../tailwind.config';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../tailwind.config';

/**
 * Helper function to simulate delay
 *
 * @param ms
 *
 * @usage await sleep(5000)
 */
export const sleep = (ms = 5000) => new Promise((resolve) => {
  setTimeout(resolve, ms);
});

// TODO: Doesn't even refresh the token
// https://github.com/nextauthjs/next-auth/issues/596#issuecomment-943453568
export const refreshAccessToken = async () => {
  const event = new Event('visibilitychange');
  document.dispatchEvent(event);
};

export default sleep;

export const extractedTailwindConfig = resolveConfig(tailwindConfig);
export const extractedTailwindTheme = extractedTailwindConfig.theme;

export const calculateMilesByZoomLevel = (zl: number) => ((40000 / 2 ** zl) * 2) * 0.62137;

export const biddingIdFormatter = (biddingId: number) => `#${biddingId.toString().padStart(6, '0')}`;

// Converts "extra-pickup-fee" to "Extra Pickup Fee"
export const formatRfpItemBidChargeKey = (chargeKey: string) => startCase(toLower(chargeKey.split('-').join(' ')));

// Can't utilize the non-object approach since react-select
// reilies on { label: string, value: string } format
export const formatFilters = (filters) => {
  const formatted = {} as FilterProps;

  Object.entries(filters).forEach(
    ([key, value]) => {
      if (isArray(value)) {
        return Object.assign(formatted, { [key]: value.map((v) => v.value) });
      }

      if (isObject(value)) {
        // @ts-ignore
        return Object.assign(formatted, { [key]: value.value });
      }

      return Object.assign(formatted, { [key]: value });
    },
  );

  return formatted;
};

export const keysToCamelCase = (object) => mapKeys(object, (_, key) => camelCase(key));

export const keysToSnakeCase = (object) => mapKeys(object, (value, key) => snakeCase(key));

export const isWithinAWeek = (date: Date) => {
  const now = new Date();
  const daysDiff = differenceInDays(date, now);
  return daysDiff <= 7;
};

export const convertDataStringToDate = (o: any) => ({
  ...o,
  createdAt: o.createdAt != null ? new Date(o.createdAt) : null,
  updatedAt: o.updatedAt != null ? new Date(o.updatedAt) : null,
});

export const isUserApproved = (user: User) => user.statuses.userStatus === 'verified'
  && user.statuses.vendorStatus === 'approved';
