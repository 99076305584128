import Button from '@app/components/button';
import Modal from '@app/components/modal';
import { useThemeContext } from '@app/contexts/theme-context';
import { useUserContext } from '@app/contexts/user-context';
import { useVendorPanelModalContext } from '@app/contexts/vendor-panel-modal-context';
import { useWasteStreamPanelContext } from '@app/contexts/waste-stream-panel-context';
import { VendorWasteStream } from '@app/contexts/waste-stream-panel-context/types';
import FormFieldReactSelect from '@app/forms/fields/react-select';
import {
  mdiChevronRight,
  mdiCloseThick, mdiMapCheck, mdiPlusThick, mdiRecycleVariant, mdiTrashCan,
} from '@mdi/js';
import Icon from '@mdi/react';
import { isEmpty } from 'lodash';
import {
  useState,
  Fragment,
  useRef,
  useMemo,
  useEffect,
} from 'react';
import { Form } from 'react-final-form';

export type VendorWasteStreamListProp = {
  isOnboarding?: boolean
};

const VendorWasteStreamList = ({ isOnboarding = false } : VendorWasteStreamListProp) => {
  const formRef = useRef(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isAddingNew, setIsaddingNew] = useState(isOnboarding);
  const {
    handleDeleteWasteStream,
    vendorWasteStreams,
    handleAddWasteStreams,
    selectedVendorWasteStream,
    setSelectedVendorWasteStream,
  } = useWasteStreamPanelContext();
  const { isMobile, isTablet } = useThemeContext();
  const { currentUser } = useUserContext();
  const {
    isWasteStreamTableAddMode,
    setIsWasteStreamTableAddMode,
    closeWasteStreamTable,
    isOpenWasteStreamTable,
    openWasteStreamTable,
  } = useVendorPanelModalContext();

  useEffect(() => {
    if (!isWasteStreamTableAddMode) return;
    setIsaddingNew(true);
  }, [isWasteStreamTableAddMode]);

  const closeAndResetModal = () => {
    closeWasteStreamTable();
    setIsaddingNew(false);
    setIsWasteStreamTableAddMode(false);
  };

  const handleDeleteConfirmation = (item: VendorWasteStream) => {
    setIsDeleteModalOpen(true);
    setSelectedVendorWasteStream(item);
  };

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await handleDeleteWasteStream(selectedVendorWasteStream.id);
      setIsDeleteModalOpen(false);
      setSelectedVendorWasteStream(null);
    } catch (error) {
      console.error(error);
      setIsDeleting(false);
    } finally {
      setIsDeleteModalOpen(false);
      setIsDeleting(false);
    }
  };

  const handleOnSubmit = async ({ wasteStream }) => {
    const wasteStreamIds: number[] = wasteStream.map((w) => w.value);
    await handleAddWasteStreams(wasteStreamIds);
    setIsaddingNew(isOnboarding);
    if (!isOnboarding || formRef.current == null) return;
    formRef.current.reset();
  };

  const renderForm = useMemo(() => (
    <div className="py-1 rounded-xl text-xs">
      <Form
        onSubmit={handleOnSubmit}
        render={({
          handleSubmit, submitting, values, form,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="w-full md:space-x-3 md:items-center
             flex md:flex-row flex-col md:space-y-0 space-y-1"
            >
              <div className="w-full">
                <FormFieldReactSelect
                  apiUrl="/auth/vendor/options/waste-stream"
                  name="wasteStream"
                  label={null}
                  isMulti={selectedVendorWasteStream == null}
                  required
                  placeholder="Waste Stream"
                  initialValue={null}
                  closeMenuOnSelect={false}
                  showCheckBox
                  hideSelectedOptions={false}
                />
              </div>
              <div className="flex space-x-3">
                <Button
                  type="submit"
                  full={isMobile}
                  size="xs"
                  disabled={isEmpty(values.wasteStream) || submitting}
                  onClick={() => {
                    formRef.current = form;
                  }}
                >
                  {submitting ? 'Saving..' : 'Add'}
                </Button>
                {!isOnboarding && (
                <Button onClick={() => setIsaddingNew(false)} full={isMobile} size="xs" buttonType="secondary">
                  <Icon path={mdiCloseThick} size={0.6} />
                </Button>
                )}
              </div>
            </div>
          </form>
        )}
      />
    </div>
  ), [isOnboarding, isMobile, currentUser?.vendor?.id]);

  const renderData = useMemo(
    () => (
      <div>
        <div className="p-3">
          <div
            className={`flex items-center flex-row justify-between ${
              isOnboarding ? '' : 'border-b pb-3 mb-3'
            }`}
          >
            {isOnboarding ? (
              <div className="text-left pb-1">
                <div className="pb-3">
                  <Icon
                    path={mdiMapCheck}
                    size={1.5}
                    className="text-secondary"
                  />
                </div>
                <span className="label-lg md:h3 text-primary-dark dark:text-primary-light">
                  Add Waste Stream
                </span>
                <br />
                <span>
                  For better matches, add your waste stream and get the right
                  notifications.
                </span>
              </div>
            ) : (
              <div className="flex space-x-2 flex-row items-center">
                <div className="flex items-center">
                  <Icon path={mdiRecycleVariant} size={1} className="text-secondary" />
                </div>
                <h4>Waste Stream</h4>
              </div>
            )}
            {!isAddingNew && currentUser.allowed.canEditVendorWasteStream && (
              <div>
                <div className="xs:hidden">
                  <Button
                    buttonType="secondary"
                    onClick={() => setIsaddingNew(true)}
                    size="xs"
                    className="dark:!bg-background-mid-em-dark"
                  >
                    <Icon path={mdiPlusThick} size={0.6} />
                  </Button>
                </div>
                <div className="hidden xs:block">
                  <Button
                    startIcon={<Icon path={mdiPlusThick} size={0.6} />}
                    buttonType="secondary"
                    onClick={() => setIsaddingNew(true)}
                    size="xs"
                    className="dark:!bg-background-mid-em-dark"
                  >
                    Add New
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div className="space-y-4 mb-4">
            {isAddingNew
              && currentUser.allowed.canEditVendorWasteStream
              && renderForm}
          </div>
          <div
            className={`${
              isAddingNew
                ? `max-h-[40vh] ${
                  isOnboarding ? '2xl:max-h-[28vh]' : '2xl:max-h-[40vh]'
                }`
                : '2xl:max-h-[50vh] max-h-[45vh]'
            } overflow-auto`}
          >
            <div
              className={`label-sm-bold  dark:bg-background-low-em-dark
        bg-background-low-em border-b-2 dark:border-background-stroke-dark
         text-text-color-mid-em dark:text-text-color-low-em p-2
         ${isMobile ? 'flex justify-between' : 'grid grid-cols-10'}`}
            >
              <div
                className={`${
                  isMobile ? '' : 'col-span-9 text-left'
                } whitespace-nowrap overflow-hidden`}
              >
                Type
              </div>
              {currentUser.allowed.canDeleteVendorWasteStream && (
                <div>Delete</div>
              )}
            </div>
            {isEmpty(vendorWasteStreams) && (
              <small>No Waste Stream found...</small>
            )}
            {vendorWasteStreams.map((contentData: VendorWasteStream) => (
              <div
                key={contentData.id}
                className="text-body-sm text-text-color-mid-em
             dark:text-text-color-low-em border-b p-2 grid grid-cols-10"
              >
                <div className="col-span-9 flex items-center whitespace-nowrap overflow-hidden">
                  <span className="text-body-sm ">
                    {contentData.wasteStream.name}
                  </span>
                </div>
                {currentUser.allowed.canDeleteVendorWasteStream && (
                  <div className="text-lg text-primary">
                    <button
                      type="button"
                      onClick={() => handleDeleteConfirmation(contentData)}
                    >
                      <Icon path={mdiTrashCan} size={0.6} />
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        {!isOnboarding && (
          <div className="text-right p-3">
            <Button
              buttonType="secondary"
              onClick={closeAndResetModal}
              className="dark:!bg-transparent"
              size="xs"
            >
              Close
            </Button>
          </div>
        )}
      </div>
    ),
    [
      isOnboarding,
      currentUser.allowed.canEditVendorWasteStream,
      currentUser.allowed.canDeleteVendorWasteStream,
      vendorWasteStreams,
      isAddingNew,
      renderForm,
    ],
  );

  const renderDeleteModalData = (
    <div className="space-y-7 text-xs">
      <h4>
        Delete Waste Stream
      </h4>
      <div>
        <p className="mb-2 ">Deleting:</p>
        <input
          className="rounded-lg border-1 w-full border-red-500 text-red-500 text-xs"
          type="text"
          value={selectedVendorWasteStream?.wasteStream.name}
          disabled
        />
      </div>
      <div className="flex space-x-3 ">
        <Button type="submit" disabled={isDeleting} full onClick={() => handleDelete()} size="xs">
          {isDeleting ? 'Deleting..' : 'Delete'}
        </Button>
        <Button buttonType="secondary" full onClick={() => setIsDeleteModalOpen(false)} size="xs">
          Cancel
        </Button>
      </div>
    </div>
  );

  return (
    <Fragment>
      {isOnboarding ? renderData : (
        <Modal
          overrideStyles={{
            content: {
              width: isMobile ? '90vw' : '70vw',
              maxHeight: '70vh',
              padding: isMobile ? 0 : 'auto',
            },
          }}
          isOpen={isOpenWasteStreamTable}
          onRequestClose={closeAndResetModal}
        >
          {renderData}
        </Modal>
      )}
      <Modal
        overrideStyles={{ content: { minWidth: '20vw' } }}
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
      >
        {renderDeleteModalData}
      </Modal>
      {!isOnboarding && (
      <button
        type="button"
        className="bg-light drop-shadow-md p-2 rounded-lg flex justify-center
        dark:bg-background-main-dark dark:border-2 dark:border-background-stroke-dark"
        onClick={openWasteStreamTable}
      >
        <span className="text-primary mx-1 flex flex-row items-center">
          {!isTablet && (
            <span>
              View All
              {' '}
            </span>
          )}
          <Icon className={isTablet ? 'ml-1' : 'ml-2'} size={0.7} path={mdiChevronRight} />
        </span>
      </button>
      )}
    </Fragment>
  );
};

export default VendorWasteStreamList;
