import Button from '@app/components/button';
import { useAppContext } from '@app/contexts/app-context';
import { useContainerPanelContext } from '@app/contexts/container-panel-context';
import { useServiceAreaContext } from '@app/contexts/service-area-context';
import { useUserContext } from '@app/contexts/user-context';
import {
  FEATURE_CONTAINER, FEATURE_SERVICE_AREA, FEATURE_WASTESTREAM, GETTING_STARTED, SERVICE_OFFERING_INTRO,
} from '@app/contexts/user-context/constants';
import { OnboardingKeyItem } from '@app/contexts/user-context/types';
import { useWasteStreamPanelContext } from '@app/contexts/waste-stream-panel-context';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';
import { isEmpty, orderBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Intro from './intro';
import ServiceAreas from './service-areas';
import VendorContainers from './vendor-containers';
import WasteStreams from './waste-streams';

const ServiceOfferingSection = () => {
  const serviceOfferingItems = [
    SERVICE_OFFERING_INTRO,
    FEATURE_SERVICE_AREA,
    FEATURE_CONTAINER,
    FEATURE_WASTESTREAM,
  ];
  const { onboardingSteps, addOnboardingStep, removeOnboardingStep } = useUserContext();
  const { serviceAreas } = useServiceAreaContext();
  const { containers } = useContainerPanelContext();
  const { vendorWasteStreams } = useWasteStreamPanelContext();
  const { isLoading } = useAppContext();
  const [selectedStep, setSelectedStep] = useState<OnboardingKeyItem>(SERVICE_OFFERING_INTRO);

  useEffect(() => {
    const unviewedItems = serviceOfferingItems.filter((item) => !onboardingSteps.some((k) => k === item.key));
    const orderedUnviewedItems = orderBy(unviewedItems, ['order']);
    if (isEmpty(orderedUnviewedItems)) return;
    setSelectedStep(orderedUnviewedItems[0]);
  }, [onboardingSteps]);

  const currentIndex = useMemo(() => selectedStep.order - 2, [selectedStep]);
  const nextButtonLabel = useMemo(() => {
    const hasNoServiceAreas = isEmpty(serviceAreas);
    const hasNoContainers = isEmpty(containers);
    const hasNoWasteStreams = isEmpty(vendorWasteStreams);

    return (
      (selectedStep.key === FEATURE_SERVICE_AREA.key && hasNoServiceAreas)
      || (selectedStep.key === FEATURE_CONTAINER.key && hasNoContainers)
      || (selectedStep.key === FEATURE_WASTESTREAM.key && hasNoWasteStreams) ? 'Skip for Now' : 'Next'
    );
  }, [selectedStep, serviceAreas, containers, vendorWasteStreams]);
  const loadingButtonLabel = useMemo(() => (nextButtonLabel === 'Next' ? 'Proceeding' : 'Skipping'), [nextButtonLabel]);

  const customDot = (
    _,
    isSelected: boolean,
    index: number,
    label: string,
  ) => (
    <li
      className="inline-block mr-3 cursor-pointer"
      key={index}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role="button"
      tabIndex={0}
      aria-label={`${label} ${index + 1}`}
    >
      <div
        className={`${isSelected ? 'bg-secondary' : 'bg-text-color-disabled'} h-[8px] w-[8px] rounded-full`}
      />
    </li>
  );

  return (
    <div>
      <Carousel
        showArrows={false}
        showStatus={false}
        selectedItem={currentIndex}
        showThumbs={false}
        renderIndicator={customDot}
        renderItem={(item) => <div className="mb-4">{item}</div>}
        swipeable={false}
      >
        <Intro />
        <ServiceAreas />
        <VendorContainers />
        <WasteStreams />
      </Carousel>
      <div className="flex flex-row justify-between px-6 py-4 bg-background-low-em dark:bg-background-low-em-dark">
        <Button
          buttonType="secondary"
          startIcon={<Icon path={mdiChevronLeft} size={1} />}
          disabled={isLoading('handleRemoveOnboardingStep')}
          onClick={() => {
            const previousOrder = selectedStep.order - 1;
            const previousStep = serviceOfferingItems.find((k) => k.order === previousOrder);
            if (previousStep == null) {
              removeOnboardingStep(GETTING_STARTED.key);
            } else {
              removeOnboardingStep(previousStep.key);
            }
          }}
        >
          Prev
        </Button>
        <Button
          buttonType="secondary"
          trailingIcon={<Icon path={mdiChevronRight} size={1} />}
          disabled={isLoading('handleAddOnboardingStep')}
          onClick={() => addOnboardingStep(selectedStep.key)}
        >
          {isLoading('handleAddOnboardingStep') ? loadingButtonLabel : nextButtonLabel}
        </Button>
      </div>
    </div>
  );
};

export default ServiceOfferingSection;
